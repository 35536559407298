import { Box, Container } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import AppNavigation from "../shared/AppNavigation";
import Footer from "../shared/Footer";

const PublicOutlet = () => {
  return (
    <>
      <AppNavigation />
      <Box className="main_body">
        <Container maxW="1080px">
          <Outlet />
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default PublicOutlet;

import React from 'react'
import { Box, color, useColorModeValue } from '@chakra-ui/react'

export default function APIKeys() {
  return (
    <>
      <h2 className="title">API Keys</h2>

      <Box
        style={{
          padding: "20px",
          backgroundColor: useColorModeValue("#fff", "#3e3d3c"),
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          fontSize: "1rem",
          fontWeight: "500",
          color: useColorModeValue("#333", "#ddd"),
        }}
      >
        Thank you for stopping by! We're working hard to put the finishing touches on this amazing experience.
        Stay tuned, APIKeys feature is coming soon! 🌟
      </Box>
    </>
  )
}

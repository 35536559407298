import React, { useEffect, useState } from "react";
import { getMyRefferalDetails } from "../../common/api";
import { withdrawRefferalReward } from "../../common/api";
import { useAccount } from "../../context/AccountProvider";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Text,
  ModalFooter,
  InputRightAddon,
  InputGroup,
  useToast,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import isEmpty from "is-empty";
import { FaCopy } from "react-icons/fa";

const Referral = () => {
  const [myRefferalDetails, setMyRefferalDetails] = useState();
  const { accountAddress, accountBalance } = useAccount();
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const getMyRefferalDetailsHandler = async () => {
    if (isEmpty(accountAddress)) return;
    const res = await getMyRefferalDetails(accountAddress);
    setMyRefferalDetails(res);
  };

  const handleWithdrawalAmountChange = (event) => {
    setWithdrawalAmount(event.target.value);
  };

  const handleWithdrawal = async () => {
    try {
      const response = await withdrawRefferalReward(
        accountAddress,
        withdrawalAmount
      );
      toast({
        title: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onCloseModal();
    } catch (error) {
      toast({
        title: "Withdrawal failed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleMaxWithdrawal = () => {
    if (myRefferalDetails?.referral_collectd.current_balance) {
      setWithdrawalAmount(myRefferalDetails.referral_collectd.current_balance);
    }
  };

  const onCloseModal = () => {
    setWithdrawalAmount("");
    onClose();
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    toast({
      title: "Address copied!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const BASE_REFERRAL_LINK = "https://tronweb.io/";
  const generateReferralLink = (accountAddress) => {
    // const lastTenDigits = accountAddress.slice(-10);
    return `${BASE_REFERRAL_LINK}?ref=${encodeURIComponent(accountAddress)}`;
  };
  const referralLink = generateReferralLink(accountAddress);

  useEffect(() => {
    getMyRefferalDetailsHandler();
  }, [accountAddress]);

  return (
    <>
      <div overflow="auto">
        <Box display={{ base: "block", md: "flex" }} justifyContent="space-between">
          <Box>
            <h2 fontSize={{ base: "sm", md: "md" }}>
              <b>Referral Link :</b>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "12px" }}>{referralLink}</span>
                <span
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    color: "gray",
                  }}
                  onClick={copyToClipboard}
                >
                  <FaCopy />
                </span>
              </div>
            </h2>
          </Box>

          <Box>
            <Text fontSize={{ base: "sm", md: "md" }}>
              Cumulative rewards :{" "}
              <span style={{ color: "red" }}>
                {myRefferalDetails?.referral_collectd?.current_balance || 0}
              </span>{" "}
              TRX
            </Text>
          </Box>
          <Box>
            <Text fontSize={{ base: "sm", md: "md" }}>
              Referrals :{" "}
              <span style={{ color: "red" }}>
                {myRefferalDetails?.referrals.length || 0}
              </span>{" "}
              People
            </Text>
          </Box>

          <Box>
            <Button className="primary" onClick={onOpen}>
              Withdrawal
            </Button>
          </Box>
        </Box>

        <Tabs
          mt="10"
          className="tw-card"
          overflow="auto"
          backgroundColor={useColorModeValue("#fff", "#3e3d3c")}
        >
          <TabList>
            <Tab>Referral List</Tab>
            <Tab>Withdrawal List</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {isEmpty(myRefferalDetails?.referrals) ? (
                <div style={{ textAlign: "center" }}>
                  <h3>No Referrals Yet</h3>
                </div>
              ) : (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Sr. No.</Th>
                      <Th>User Address</Th>
                      <Th>Is Active</Th>
                      <Th>Referrla Amount</Th>
                      <Th>Signup Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {myRefferalDetails?.referrals.map((i, index) => (
                      <Tr key={i.user_address}>
                        <Td>{index + 1}</Td>
                        <Td>{i.user_address}</Td>
                        <Td>{i.is_active ? "Yes" : "No"}</Td>
                        <Td>{i.referral_collected_amount}</Td>
                        <Td>{i.createdAt}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </TabPanel>
            <TabPanel>
              {isEmpty(myRefferalDetails?.referrals) ? (
                <div style={{ textAlign: "center" }}>
                  <h3>No Data Found</h3>
                </div>
              ) : (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>User Address</Th>
                      <Th>Signup Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {myRefferalDetails?.referrals.map((i) => (
                      <Tr key={i.user_address}>
                        <Td>{i.user_address}</Td>
                        <Td>{i.createdAt}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Modal isOpen={isOpen} onClose={onCloseModal} size={"md"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Withdrawal Amount</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                {/* <FormLabel>Withdrawal Amount</FormLabel> */}
                <InputGroup>
                  <Input
                    type="number"
                    placeholder="Enter the amount"
                    value={withdrawalAmount}
                    onChange={handleWithdrawalAmountChange}
                  />
                  <InputRightAddon
                    children="Max"
                    onClick={handleMaxWithdrawal}
                    cursor="pointer"
                    color="gray.500"
                  />
                </InputGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter gap={3}>
              <Button className="primary" onClick={handleWithdrawal}>
                Withdraw
              </Button>
              <Button className="secondary" onClick={onCloseModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default Referral;

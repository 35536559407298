import { useEffect } from "react";
import { router } from "./routes";
import { RouterProvider } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountDetails,
  getTransactionResourceHandler,
  getWalletDetails,
} from "./redux/accountSlice";

function App() {
  const dispatch = useDispatch();
  const { accountAddress } = useSelector((state) => state.account);

  useEffect(() => {
    window.addEventListener("message", (res) => {
      if (res.data.message && res.data.message.action === "setAccount") {
        if (window.tronWeb) {
          if (res.data.message.data.address !== accountAddress) {
            localStorage.clear();
            window.location.reload();
          }
        }
      }
    });
  }, [accountAddress]);
  useEffect(() => {
    const interval = setInterval(async () => {
      dispatch(getWalletDetails());
      //wallet checking interval 2sec
    }, 3000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountAddress) {
      dispatch(fetchAccountDetails());
    }
  }, [accountAddress, dispatch]);

  useEffect(() => {
    dispatch(getTransactionResourceHandler());
  }, [dispatch]);

  return <RouterProvider router={router} />;
}

export default App;

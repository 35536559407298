import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";

import PublicOutlet from "../layout/PublicLayout";
import Operate from "../pages/Admin/admin";
import Market from "../pages/Market";
import Terms from "../pages/Policies/Terms";
import Stake from "../pages/Stake/Stake";
import DashboardOutlet from "../layout/DashboardLayout";
import Dashboard from "../pages/Dashboard";
import Referral from "../pages/Referral";
import { AuthLayout } from "../layout/AuthLayout";
import MyOrders from "../pages/MyOrders";
import APIKeys from "../pages/APIKeys";

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("address");
      resolve(user);
    }, 100)
  );

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route path="/" element={<PublicOutlet />}>
        <Route path="/" element={<Market />} />
      </Route>
      <Route path="/" element={<PublicOutlet />}>
        <Route path="/stake" element={<Stake />} />
      </Route>
      <Route path="/" element={<PublicOutlet />}>
        <Route path="/maintain" element={<Operate />} />
      </Route>

      {/* <Route path="/" element={<PublicOutlet />}>
          <Route path="/advertise" element={<Advertise />} />
        </Route> */}
      <Route path="/" element={<PublicOutlet />}>
        <Route path="/terms" element={<Terms />} />
      </Route>
      <Route path="/" element={<DashboardOutlet />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/myorders" element={<MyOrders />} />
        <Route path="/API-keys" element={<APIKeys />} />
      </Route>
    </Route>
  )
);

/* eslint-disable react-hooks/rules-of-hooks */
import "./OrderComponent.css";
import React, { useEffect, useState } from "react";
import { capitalCase } from "change-case";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Text,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import isEmpty from "is-empty";
import { useAccount } from "../../context/AccountProvider";
import { fillOrder, getOpenOrders } from "../../common/api";
import { initiateSocket, subscribeOpenOrders } from "../../common/api/socket";
import {
  calculateOrderAmount,
  calculateResourceTron,
  toSun,
  toTron,
} from "../../common/utils";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import { useCallback } from "react";
import { ENERGY } from "../../common/utils/constants";
const OrderComponent = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    accountAddress,
    accountBalance,
    noWalletDetect,
    tronResources,
    accountDetails,
  } = useAccount();
  const [socket, setSocket] = useState();
  const [orderData, setOrderData] = useState({
    resourceAmount: "",
  });
  const [openOrders, setOpenOrders] = useState([]);
  const [orderLoader, setOrderLoader] = useState(false);
  const [isWalletBalanceSufficient, setIsWalletBalanceSufficient] =
    useState(false);
  const [isOrderValid, setOrderValid] = useState(false);
  const [sharePercentage, setSharePercentage] = useState(0);
  const [minimumUserIncome, setMinimumUserIncome] = useState(4);
  const toast = useToast();
  const outerLimit = 2;
  const innerLimit = 2;
  const [totalPages, setTotalPages] = useState(1);
  const {
    pages,
    pagesCount,
    offset,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalPages,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: totalPages,
      isDisabled: false,
      currentPage: 1,
    },
  });
  // effects
  useEffect(() => {}, [currentPage, pageSize, offset]);

  // handlers
  const handlePageChange = (nextPage) => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
  };

  const handlePageSizeChange = (e) => {
    const pageSize = Number(e.target.value);

    setPageSize(pageSize);
  };

  const handleDisableClick = () => {
    setIsDisabled((oldState) => !oldState);
  };

  const getOpenOrdersHandler = async (page) => {
    const res = await getOpenOrders(page);
    setOpenOrders(res.data);
    setTotalPages(res.totalPages);
  };

  const updateOrderData = useCallback(
    (i) => {
      if (!i) {
        toast({
          title: `Order closed`,
          position: "top-right",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
        return;
      }
      let freezeAmount = calculateResourceTron(
        i?.resource_type,
        i?.resource_amount,
        accountDetails
      );
      let income = toTron((sharePercentage / 100) * i.order_amount);
      setOrderData({
        ...i,
        freezeAmount,
        income,
        resourceAmount: i.resource_amount,
      });
    },
    [calculateResourceTron, sharePercentage]
  );

  const updateFillOrderInput = (value) => {
    let freezeAmount = calculateResourceTron(
      orderData.resource_type,
      value,
      accountDetails
    );
    setOrderData({
      ...orderData,
      freezeAmount,
      resourceAmount: value,
    });
  };

  const checkWalletBalanceSufficient = (resource_type, resource_amount) => {
    if (isEmpty(accountDetails)) return;
    let resourceAvailable =
      resource_type === ENERGY
        ? accountDetails.maxDelegatableEnergy
        : accountDetails.maxDelegatableBandwidth;
    let requiredResource = resource_amount - resourceAvailable;
    accountBalance >
    calculateResourceTron(resource_type, requiredResource, accountDetails)
      ? setIsWalletBalanceSufficient(false)
      : setIsWalletBalanceSufficient(true);
  };

  const fillOrderHandler = async (order) => {
    if (noWalletDetect) {
      return toast({
        title: `Wallet not connected`,
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setOrderLoader(true);
    const res = await fillOrder(order, accountAddress, accountDetails);
    if (res?.data?.status) {
      toast({
        title: res?.data?.message ?? `Transaction completed successfully`,
        position: "top-right",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } else {
      toast({
        title: `Something went wrong`,
        position: "top-right",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setOrderLoader(false);
  };

  const updateOpenOrders = (id) => {
    if (id === orderData._id) {
      onClose();
    }
  };

  const finalResourcesRequired = (resourceAmount, resource_type) => {
    if (isEmpty(accountAddress)) return "-";
    const { maxDelegatableEnergy } = accountDetails;
    const remainingResourceAmount =
      parseInt(resourceAmount) - maxDelegatableEnergy;
    const resourceTron = calculateResourceTron(
      resource_type,
      remainingResourceAmount,
      accountDetails
    );
    let formattedEnergy = !isEmpty(maxDelegatableEnergy)
      ? maxDelegatableEnergy.toFixed(2)
      : 0;
    if (isEmpty(resourceAmount) || resourceAmount > orderData.resource_amount)
      return (
        calculateResourceTron(
          resource_type,
          orderData.resource_amount,
          accountDetails
        ) + " TRX"
      );
    if (remainingResourceAmount < 0)
      return `${resourceAmount} ${resource_type?.toUpperCase()}`;
    if (maxDelegatableEnergy <= 0) {
      return `${resourceTron} TRX`;
    } else {
      return `${resourceTron} TRX + ${formattedEnergy} ${resource_type?.toUpperCase()}`;
    }
  };

  useEffect(() => {
    checkOrderValid();
    const socketTemp = initiateSocket();
    setSocket(socketTemp);
    setSharePercentage(tronResources?.share_percentage ?? 0);
    getOpenOrdersHandler(currentPage);
    subscribeOpenOrders((err, data) => {
      setOpenOrders(data.data);
      setTotalPages(data.totalPages);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(openOrders) && !isEmpty(orderData._id)) {
      updateOrderData(openOrders?.find((j) => j._id === orderData._id));
    }
  }, [openOrders, orderData._id]);

  const calculateIncome = (resourceAmount, i) => {
    if (isEmpty(resourceAmount)) return 0;
    return toTron(
      (sharePercentage / 100) *
        calculateOrderAmount(
          parseInt(resourceAmount),
          i.price_per_day,
          i.freeze_duration
        )
    );
  };

  const checkOrderValid = () => {
    parseInt(orderData.resourceAmount) <= orderData.resource_amount ||
    minimumUserIncome <= calculateIncome(orderData.resourceAmount, orderData)
      ? setOrderValid(true)
      : setOrderValid(false);
  };

  const calculateMinOrderFromIncome = (freezeDuration, value) => {
    return Math.ceil(
      toSun(
        minimumUserIncome / ((sharePercentage / 100) * value * freezeDuration)
      )
    );
  };

  return !isEmpty(openOrders) ? (
    <>
      <h2 className="title">Open Orders </h2>
      <TableContainer
        mt="5"
        py={"5"}
        className="tw-card"
        backgroundColor={useColorModeValue("#fff", "#3e3d3c")}
      >
        {!isEmpty(openOrders) ? (
          <>
            <Table variant="unstyled" size="sm">
              <Thead>
                <Tr>
                  <Th>Buyer</Th>
                  <Th>Seller</Th>
                  <Th>Duration</Th>
                </Tr>
              </Thead>
              <Tbody>
                {openOrders.map((i) => {
                  return (
                    <Tr key={i._id}>
                      <Td>
                        Price/Day: <b>{i.price_per_day}</b> sun
                        <br />
                        <span>{capitalCase(i.resource_type)}</span> :{" "}
                        {i.resource_amount}
                      </Td>
                      <Td>
                        Income :{" "}
                        <span className="green">
                          <b>{calculateIncome(i.resource_amount, i)} TRX</b>
                        </span>
                        <br />
                        {!isEmpty(accountDetails) ? (
                          <>
                            Freeze :{" "}
                            {calculateResourceTron(
                              i.resource_type,
                              i.resource_amount,
                              accountDetails
                            )}{" "}
                            TRX
                          </>
                        ) : null}
                        <br />
                        APY : 18%
                      </Td>
                      <Td>{i.freeze_duration} Days</Td>
                      <Td>
                        {accountAddress !== i.order_generated_by ? (
                          <Button
                            colorScheme="red"
                            size={"sm"}
                            variant="outline"
                            onClick={() => {
                              updateOrderData(i);
                              onOpen();
                              checkWalletBalanceSufficient(
                                i.resource_type,
                                i.resource_amount
                              );
                            }}
                            isDisabled={isEmpty(accountAddress)}
                          >
                            Fill Order
                          </Button>
                        ) : (
                          <Button
                            colorScheme="red"
                            size={"xs"}
                            variant="outline"
                            disabled={true}
                          >
                            Your Order
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {totalPages > 1 ? (
              <Stack>
                <Pagination
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  isDisabled={isDisabled}
                  onPageChange={handlePageChange}
                >
                  <PaginationContainer
                    align="center"
                    justify="space-between"
                    p={4}
                    w="full"
                  >
                    <PaginationPrevious
                      _hover={{ bg: "gray.300" }}
                      bg="gray.200"
                      onClick={() => getOpenOrdersHandler(currentPage - 1)}
                    >
                      <Text>Previous</Text>
                    </PaginationPrevious>
                    <PaginationPageGroup isInline align="center">
                      {pages.map((page) => (
                        <PaginationPage
                          w={7}
                          bg="gray.200"
                          key={`pagination_page_${page}`}
                          page={page}
                          onClick={() => getOpenOrdersHandler(page)}
                          fontSize="sm"
                          _hover={{
                            bg: "gray.300",
                          }}
                          _current={{
                            bg: "gray.300",
                            fontSize: "sm",
                            w: 7,
                          }}
                        />
                      ))}
                    </PaginationPageGroup>
                    <PaginationNext
                      _hover={{ bg: "gray.300" }}
                      bg="gray.200"
                      onClick={() => getOpenOrdersHandler(currentPage + 1)}
                    >
                      <Text>Next</Text>
                    </PaginationNext>
                  </PaginationContainer>
                </Pagination>
              </Stack>
            ) : null}
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h3>No Data Available</h3>
          </div>
        )}
      </TableContainer>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fill Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel mt={4}>
                <span>{capitalCase(orderData?.resource_type ?? " ")}</span> :{" "}
                {orderData?.resource_amount}
              </FormLabel>
              <InputGroup>
                <Input
                  type={"number"}
                  onChange={(e) => {
                    setOrderData((prev) => ({
                      ...prev,
                      resourceAmount: e.target.value,
                    }));
                    checkWalletBalanceSufficient(
                      orderData.resource_type,
                      e.target.value
                    );
                    checkOrderValid();
                  }}
                  placeholder={capitalCase(orderData?.resource_type ?? " ")}
                  // defaultValue={orderData?.resource_amount}
                  value={orderData?.resourceAmount}
                  max={orderData?.resource_amount}
                  isInvalid={
                    minimumUserIncome >=
                    calculateIncome(orderData.resourceAmount, orderData)
                  }
                />
                <InputRightAddon
                  children="Max"
                  onClick={() => {
                    updateFillOrderInput(orderData?.resource_amount);
                    checkWalletBalanceSufficient(
                      orderData.resource_type,
                      orderData.resource_amount
                    );
                  }}
                  style={{ cursor: "pointer" }}
                />
              </InputGroup>
              {isWalletBalanceSufficient && (
                <Text fontSize="xs" color="crimson" mt="1">
                  Insufficient Wallet Balance
                </Text>
              )}
              {minimumUserIncome >=
                calculateIncome(orderData.resourceAmount, orderData) && (
                <Text fontSize="xs" color="crimson" mt="1">
                  Minimum Amount to freeze{" "}
                  {calculateMinOrderFromIncome(
                    orderData.freeze_duration,
                    orderData.price_per_day
                  ) +
                    " " +
                    orderData.resource_type}
                </Text>
              )}
              <FormControl>
                <FormLabel mt={5}>Receiving Income Address</FormLabel>
                <Input
                  type="text"
                  placeholder={"Wallet Address"}
                  defaultValue={accountAddress}
                  disabled={true}
                />
              </FormControl>
              <Box
                p="1"
                mt={5}
                boxShadow="md"
                rounded={"lg"}
                border="1px solid #ccc"
                fontSize={"16px"}
              >
                <Table variant="unstyled" size="sm">
                  <Tbody>
                    <Tr style={{ verticalAlign: "text-bottom" }}>
                      <Td width={"165px"}>Available</Td>
                      <Td>: {accountBalance} TRX</Td>
                    </Tr>
                    <Tr style={{ verticalAlign: "text-bottom" }}>
                      <Td width={"165px"}>Delegation Amount</Td>
                      <Td>
                        :{" "}
                        {finalResourcesRequired(
                          orderData.resourceAmount,
                          orderData.resource_type
                        )}
                      </Td>
                    </Tr>
                    <Tr style={{ verticalAlign: "text-bottom" }}>
                      <Td width={"165px"}>Delegation Duration</Td>
                      <Td>: {orderData.freeze_duration} Days</Td>
                    </Tr>
                    <Tr style={{ verticalAlign: "text-bottom" }}>
                      <Td width={"165px"}>Income</Td>
                      <Td>
                        :{" "}
                        <span className="green">
                          <b>
                            {calculateIncome(
                              orderData.resourceAmount,
                              orderData
                            )}{" "}
                            TRX
                          </b>
                        </span>{" "}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              className="primary"
              mr={5}
              onClick={() => fillOrderHandler(orderData)}
              isDisabled={!isOrderValid}
              isLoading={orderLoader}
            >
              Fill Order
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null;
};

export default OrderComponent;

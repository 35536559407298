import { configureStore } from "@reduxjs/toolkit";
import accountSlice from "./accountSlice";
import orderSlice from "./orderSlice";

const store = configureStore({
  reducer: {
    account: accountSlice,
    order: orderSlice,
  },
});

export default store;

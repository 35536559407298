import { Badge, Button, Checkbox, FormControl, FormLabel, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select, Switch, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react'
import { fillOrderByAdmin, getPlatformStats, getTransactionResource, getTronProvidersList, processPendingIncomeByAdmin, removeCommunityMember, undelegatableCount, unfreezeCompletedOrders, updateOpenOrderStatus, updateOrderResourceListByAdmin, updateProviderList } from '../../common/api';
import { useToast } from '@chakra-ui/react'
import { BANDWIDTH, ENERGY } from '../../common/utils/constants';
import isEmpty from 'is-empty';
import { useAccount } from '../../context/AccountProvider';

export default function Admin() {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { tronResources } = useAccount();
    const finalRef = React.useRef(null)
    const [providersList, setProvidersList] = useState()
    const [providerAddress, setProviderAddress] = useState()
    const [providerShare, setProviderShare] = useState()
    const [isCommunityAddress, setIsCommunityAddress] = useState(true)
    const [platformStats, setPlatformStats] = useState([])



    const getTronProvidersListHandler = async (page) => {
        const res = await getTronProvidersList(page);
        setProvidersList(res.data);
    }

    const unfreezeCompletedOrdersHandler = async () => {
        const res = await unfreezeCompletedOrders()
        if (res.status === 200) {
            toast({
                title: res.data.message,
                description: "Completed Order Unfrozen Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } else {
            toast({
                title: res.data.message,
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }


    const removeCommunityMemberHandler = async (address) => {
        const res = await removeCommunityMember(address)
        if (res.status === 200) {
            toast({
                title: res.data.message.message,
                description: "User Removed Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } else {
            toast({
                title: res.data.message,
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }





    const fillOrderByAdminHandler = async () => {
        const res = await fillOrderByAdmin()
        if (res.status === 200) {
            toast({
                title: res.data.message,
                description: "Order Filled Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } else {
            toast({
                title: res.data.message,
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const processPendingIncomeByAdminHandler = async () => {
        const res = await processPendingIncomeByAdmin()
        if (res.status === 200) {
            toast({
                title: res.data.message,
                description: "Pending Income Processed Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } else {
            toast({
                title: res.data.message,
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const updateOrderResourceListHandler = async (value) => {
        const res = await updateOrderResourceListByAdmin(value)
        if (res.status === 200) {
            toast({
                title: res.data.message,
                description: "Order Resource List Updated Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
        } else {
            toast({
                title: res.data.message,
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const updateProviderListHandler = async () => {
        let data = {
            tron_address: providerAddress,
            is_community_address: isCommunityAddress,
            share_percentage: parseInt(providerShare)
        }
        const res = await updateProviderList(data)
        if (res.status === 200) {
            toast({
                title: res.data.message,
                description: "Provider Updated Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            onClose()
        } else {
            toast({
                title: res.data.message,
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const updateOpenOrderStatusHandler = async (data) => {
        const res = await updateOpenOrderStatus(data)
        if (res.status) {
            toast({
                title: res.data.message,
                description: "Order Status Updated Successfully",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            getTransactionResourceHandler()
        } else {
            toast({
                title: res.message.message || "Somthing went wrong",
                description: "--",
                status: 'warning',
                duration: 9000,
                isClosable: true,
            })
        }
    }


    const getTransactionResourceHandler = () => {
        getTransactionResource()
    }


    const getPlatformStatsHandler = async () => {
        const stats = await getPlatformStats()
        setPlatformStats(stats.data.data)
    }

    useEffect(() => {
        getTronProvidersListHandler()
    }, [])

    return (
        <>
            <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                <GridItem w='100%' >
                    <Button colorScheme='green' onClick={fillOrderByAdminHandler} size='sm'>
                        Process Open Orders
                    </Button>
                </GridItem>

                <GridItem w='100%' >
                    <Button colorScheme='orange' onClick={processPendingIncomeByAdminHandler} size='sm'>
                        Process Pending Income
                    </Button>
                </GridItem>

                <GridItem w='80%' >
                    <Button colorScheme='teal' onClick={unfreezeCompletedOrdersHandler} size='sm'>
                        Unfreeze Completed Orders
                    </Button>
                </GridItem>

                <GridItem w='100%'  >
                    <Select colorScheme='gray' placeholder={tronResources?.resource_type_status?.resource_type_paused ?? "Null"} onChange={(e) => updateOrderResourceListHandler(e.target.value)} size='sm'>
                        <option value='NONE'>NONE</option>
                        <option value='ALL'>ALL</option>
                        <option value={ENERGY}>{ENERGY}</option>
                        <option value={BANDWIDTH}>{BANDWIDTH}</option>
                    </Select>
                </GridItem>

                <GridItem w='80%'>
                    <Button colorScheme='red' size='sm' onClick={onOpen} >
                        Add Tron Provider
                    </Button>
                </GridItem>
                <GridItem w='100%' >
                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='order' mb='0'>
                            Order Status
                        </FormLabel>
                        <Switch id='order' isChecked={tronResources?.orders_open?.open_order ?? false} onChange={(e) => updateOpenOrderStatusHandler(e.target.checked)} />
                    </FormControl>
                </GridItem>
            </Grid>

            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Address</Th>
                            <Th>Share %</Th>
                            <Th isNumeric>Is Community Member</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            !isEmpty(providersList) ?
                                providersList.map((e) => (
                                    <Tr>
                                        <Td>{e.tron_address}</Td>
                                        <Td>{e.share_percentage}</Td>
                                        <Td>{e.is_community_address ? <Badge colorScheme='purple'>Yes</Badge> : <Badge colorScheme='green'>Admin</Badge>}</Td>
                                        <Td><span onClick={() => removeCommunityMemberHandler(e.tron_address)}>Remove</span></Td>
                                    </Tr>
                                )
                                ) : null
                        }
                    </Tbody>
                </Table>


                <h3>Overview</h3>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Total Platform Income ()</Th>
                            <Th>Admin Share</Th>
                            <Th isNumeric>Community Share</Th>
                            <Th isNumeric>Reffeal Share</Th>
                            <Th isNumeric>Community Income sent</Th>
                            <Th isNumeric>Referral Income sent</Th>
                            <Th isNumeric>Total Order Returned</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td isNumeric>{platformStats?.totalAmountSentAdmin ?? 0}</Td>
                            <Td isNumeric>{platformStats?.totalAmountSentUser ?? 0}</Td>
                            <Td isNumeric>{platformStats?.totalTronReceived ?? 0}</Td>
                            <Td isNumeric>{platformStats?.totalTronSent ?? 0}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Tron Provider</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Provider Address</FormLabel>
                            <Input type='text' placeholder='Tron Address' onChange={(e) => setProviderAddress(e.target.value)} />
                            <FormLabel>Amount</FormLabel>
                            <NumberInput max={50} min={10}>
                                <NumberInputField placeholder='Max 40' onChange={(e) => setProviderShare(e.target.value)} max={40} />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            <Checkbox mt={4} defaultChecked onChange={(e) => setIsCommunityAddress(e.target.checked)}>Is Community Address</Checkbox>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => updateProviderListHandler()}>
                            Add
                        </Button>
                        <Button variant='ghost' onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/app.scss";
import "./assets/styles/size.scss";
import App from "./App";
import theme from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import AccountProvider from "./context/AccountProvider";
import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/toast";
import { Provider } from "react-redux";
import store from "./redux/store";

const { ToastContainer, toast } = createStandaloneToast();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.patch["Access-Control-Allow-Methods"] = "*";

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem("token");
    if (token) {
      reqConfiglocal.headers.Authorization = `Bearer ${token}`;
    }
    return reqConfiglocal;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AccountProvider>
        <Provider store={store}>
          <App />
        </Provider>
        <ToastContainer />
      </AccountProvider>
    </ChakraProvider>
  </React.StrictMode>
);

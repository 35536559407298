import {
  Box,
  Button,
  Link,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  useDisclosure,
  ModalFooter,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import smartTruncate from 'smart-truncate';
import ModalComponent from '../../components/Modal';
import Head from './Head';
import { getMyFreezeList, undelegateresource } from '../../common/api';
import OrderComponent from './OrderComponent';
import { toTron } from '../../common/utils';
import isEmpty from 'is-empty';
import RecentTrades from './RecentTrades';
import Admin from '../Admin/admin';
import AdminOverview from '../Admin/overview';
import Order from './Order/Order';
import { ENERGY, BANDWIDTH, ORDERSTATUS } from '../../common/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { checkAdmin, setMyFreezeList } from '../../redux/accountSlice';
import {
  cancelOrderHandler,
  getUserOrderHandler,
  setIsCancelLoading,
  setIsCancelOpen,
} from '../../redux/orderSlice';

const Market = () => {
  const dispatch = useDispatch();
  const {
    accountAddress,
    accountBalance,
    totalFrozenBalance,
    isDataLoading,
    accountDetails,
    noWalletDetect,
    isAdmin,
    tronResources,
    myFreezeList,
  } = useSelector((state) => state.account);
  const { myOpenOrder, isCancelLoading, isCancelOpen } = useSelector(
    (state) => state.order
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [cancelOrderData, setCancelOrderData] = useState([]);

  const toast = useToast();

  const undelegateresourceHandler = async (item) => {
    setIsCancelLoading(true);
    try {
      await undelegateresource(
        item.receiverAddress,
        item.resource,
        item.balance,
        accountAddress
      );
      fetchMyFreezeList();
      toast({
        title: 'Resource Reclaimed Successfully',
        position: 'top-right',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: error || `Something went wrong`,
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsCancelLoading(false);
    }
  };

  const checkWallet = () => {
    if (noWalletDetect) {
      toast({
        title: `Wallet not connected`,
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else if (!tronResources?.orders_open?.open_order) {
      toast({
        title: `Order Temporary Disabled`,
        position: 'top-right',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    } else {
      onOpen();
    }
  };

  const fetchMyFreezeList = useCallback(async () => {
    if (isEmpty(accountAddress)) return;
    const _myFreezeList = await getMyFreezeList(accountAddress, 2);
    dispatch(setMyFreezeList(_myFreezeList));
  }, [accountAddress, dispatch]);

  useEffect(() => {
    dispatch(getUserOrderHandler(ORDERSTATUS.PENDING));
    fetchMyFreezeList();
    dispatch(checkAdmin());
  }, [accountAddress, dispatch, fetchMyFreezeList]);

  return (
    <>
      <Head
        accountAddress={accountAddress}
        accountBalance={accountBalance}
        totalFrozenBalance={totalFrozenBalance}
        isDataLoading={isDataLoading}
        accountDetails={accountDetails}
      />

      <Box pt={5} px={{ base: 2, sm: 12, md: 17 }}>
        {!isAdmin && (
          <Box display='flex' justifyContent='flex-end' mb={5}>
            <Stack direction='row' spacing={4}>
              <Button className='primary' onClick={() => checkWallet()}>
                Buy
              </Button>
              <Button className='secondary'>Sell</Button>
            </Stack>
          </Box>
        )}

        {/* Only for Admin */}
        {isAdmin && <Admin />}
        {isAdmin && <AdminOverview />}

        <OrderComponent />
        <h2 className='title'> Recently Filled Orders</h2>
        <Tabs
          mt='2'
          className='tw-card'
          backgroundColor={useColorModeValue('#fff', '#3e3d3c')}
        >
          <TabList>
            <Tab>Recent Trades</Tab>
            <Tab>My Delegated List</Tab>
            <Tab onClick={getUserOrderHandler}>My Open Orders</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TableContainer
                py={'5'}
                className='shadow--outset'
                rounded={'lg'}
              >
                <RecentTrades />
              </TableContainer>
            </TabPanel>

            <TabPanel>
              <TableContainer
                mt='5'
                py={'5'}
                className='shadow--outset'
                rounded={'lg'}
              >
                {!isEmpty(myFreezeList) ? (
                  <Table variant='unstyled' size='sm'>
                    <Thead>
                      <Tr>
                        <Th>Delegated To</Th>
                        <Th>Resource</Th>
                        <Th>Amount</Th>
                        <Th>Expire Date</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {myFreezeList?.map((item, i) => (
                        <Tr key={i}>
                          <Td>
                            {smartTruncate(item.receiverAddress, 18, {
                              position: 8,
                            })}
                          </Td>
                          <Td>{item.resource == 1 ? ENERGY : BANDWIDTH}</Td>
                          <Td>{item.resourceValue}</Td>
                          <Td>
                            {!isEmpty(item.expireTime)
                              ? moment(item.expireTime).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )
                              : '-'}
                          </Td>
                          <Td>
                            <Button
                              className='primary'
                              size={'sm'}
                              mr={5}
                              onClick={() => undelegateresourceHandler(item)}
                              disabled={item.expireTime > Date.now()}
                              isLoading={isCancelLoading}
                            >
                              Reclaim
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <h3>No Data Available</h3>
                  </div>
                )}
              </TableContainer>
            </TabPanel>

            <TabPanel>
              <TableContainer
                mt='5'
                py={'5'}
                className='shadow--outset'
                rounded={'lg'}
              >
                {!isEmpty(myOpenOrder) ? (
                  <Table variant='unstyled' size='sm'>
                    <Thead>
                      <Tr>
                        <Th>Resource</Th>
                        <Th>Price/day</Th>
                        <Th>Payout</Th>
                        <Th>Date</Th>
                        <Th>TXN ID</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {myOpenOrder?.map((e) => {
                        return (
                          <Tr key={e.txn_id + e._id}>
                            <Td>
                              {e.resource_amount} {e.resource_type}
                            </Td>
                            <Td>{e.price_per_day} SUN</Td>
                            <Td>{toTron(e.order_amount)} TRX</Td>
                            <Td>
                              {moment(e.created_at).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )}
                            </Td>
                            <Td>
                              <Link
                                href={`https://tronscan.org/#/transaction/${e.txn_id}`}
                                isExternal
                              >
                                {smartTruncate(e.txn_id, 18, { position: 8 })}
                              </Link>
                            </Td>
                            <Td>
                              <Button
                                onClick={() => {
                                  setCancelOrderData(e);
                                  dispatch(setIsCancelOpen(true));
                                }}
                              >
                                Cancel
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <h3>No Data Available</h3>
                  </div>
                )}
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Cancel Order Modal */}
      <ModalComponent
        isOpen={isCancelOpen}
        onClose={() => dispatch(setIsCancelOpen(false))}
        title='Cancel Order'
      >
        <Box className='form_body'>
          <TableContainer>
            <Table variant='unstyled' size='sm'>
              <Tbody>
                <Tr>
                  <Td>Left Energy</Td>
                  <Td>{cancelOrderData.resource_amount}</Td>
                </Tr>
                <Tr>
                  <Td>Return TRX</Td>
                  <Td>{toTron(cancelOrderData.order_amount) - 1} TRX</Td>
                </Tr>
                <Tr>
                  <Td>Cancelation Fee</Td>
                  <Td>1 TRX</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <ModalFooter>
            <Button
              className='primary'
              mr={5}
              onClick={() =>
                dispatch(cancelOrderHandler(cancelOrderData._id))
                  .then((res) => {
                    if (res.status) {
                      dispatch(setIsCancelLoading(false));
                      dispatch(setIsCancelOpen(false));
                      dispatch(getUserOrderHandler(ORDERSTATUS.PENDING));
                      toast({
                        title: res.data.message,
                        position: 'top-right',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                      });
                    } else {
                      dispatch(setIsCancelLoading(false));
                      toast({
                        title: `Something went wrong`,
                        position: 'top-right',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                      });
                    }
                  })
                  .catch((error) => {
                    toast({
                      title: error.message || `Something went wrong`,
                      position: 'top-right',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    });
                  })
              }
              disabled={isCancelLoading}
              isLoading={isCancelLoading}
            >
              Cancel Order
            </Button>
            <Button onClick={() => dispatch(setIsCancelOpen(false))}>
              Close
            </Button>
          </ModalFooter>
        </Box>
      </ModalComponent>
      <Order onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default Market;

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import {
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tag,
  TagLabel,
  Tooltip,
} from "@chakra-ui/react";
import { getTrades } from "../../common/api";
import { toTron } from "../../common/utils";
import { BANDWIDTH, ENERGY } from "../../common/utils/constants";
import {
  initiateSocket,
  subscribeFinishedOrders,
} from "../../common/api/socket";
import isEmpty from "is-empty";
import moment from "moment";
import smartTruncate from "smart-truncate";

export default function RecentTrades() {
  const [recentTrade, setRecentTrade] = useState([]);
  const [socket, setSocket] = useState();

  const getTradesHandler = async (page) => {
    const res = await getTrades("COMPLETED", page);
    setRecentTrade(res.data);
  };

  useEffect(() => {
    getTradesHandler(1);
    const socketTemp = initiateSocket();
    setSocket(socketTemp);
    subscribeFinishedOrders((err, data) => {
      setRecentTrade((recentTrade) => [data, ...recentTrade]);
    });
  }, []);

  return (
    <>
    <div style={{ overflowX: "auto" }}>
      {!isEmpty(recentTrade) ? (
        <>
          <Table variant="unstyled" size={{ base: "sm"}}>
            <Thead>
              <Tr>
                <Th>Resource</Th>
                <Th>Price/day</Th>
                <Th>Payout</Th>
                <Th>Date</Th>
                <Th>TXN ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recentTrade?.map((e) => {
                return (
                  <Tr key={e.txn_id + e._id}>
                    <Td>
                      <Tag
                        size={{ md: "sm" }}
                        key={"sm"}
                        variant="outline"
                        colorScheme={
                          e.resource_type === ENERGY ? "red" : "teal"
                        }
                      >
                        <Tooltip
                          label={
                            e.resource_type === ENERGY ? ENERGY : BANDWIDTH
                          }
                          placement="auto-start"
                        >
                          <TagLabel>
                            {e.resource_type === ENERGY ? "E" : "B"}
                          </TagLabel>
                        </Tooltip>
                      </Tag>{" "}
                      {e.resource_amount_ordered}
                    </Td>
                    <Td>{e.price_per_day} SUN</Td>
                    <Td>{toTron(e.order_amount)} TRX</Td>
                    <Td>
                      {moment(e.created_at).format("YYYY-MM-DD HH:mm:ss")}
                    </Td>
                    <Td>
                      <Link
                        fontSize={{ base: "sm", md: "md" }}
                        href={`https://tronscan.org/#/transaction/${e.txn_id}`}
                        isExternal
                      >
                        {smartTruncate(e.txn_id, 18, {
                          position: 8,
                        })}
                      </Link>
                    </Td>
                  </Tr>
                );
              }) ?? null}
            </Tbody>
          </Table>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h3>No Data Available</h3>
        </div>
      )}
      </div>
    </>
  );
}

import React from "react";
import { HStack, Input, useNumberInput, IconButton } from "@chakra-ui/react";
import { IoRemoveOutline, IoAdd } from "react-icons/io5";

const PlusMinusInput = ({ updateState, minValue, maxValue, name, value }) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: minValue,
      min: minValue,
      max: maxValue,
    });

  React.useEffect(() => {
    updateState(getInputProps().value, name);
  }, [getInputProps().value]);

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  return (
    <>
      <HStack maxW="180px">
        <IconButton icon={<IoRemoveOutline />} {...dec} />
        <Input {...input} value={value} />
        <IconButton icon={<IoAdd />} {...inc} />
      </HStack>
    </>
  );
};

export default PlusMinusInput;

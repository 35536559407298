import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

const CustomInput = ({ type, name, value, onChange, label, ...rest }) => {
  return (
    <>
      <FormControl>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Input
          id={name}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          {...rest}
        />
        {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
      </FormControl>
    </>
  );
};

export default CustomInput;

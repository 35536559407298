import io from 'socket.io-client'
let socket
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL

export const initiateSocket = () => {
    socket = io(SOCKET_URL, {
        forceNew: true
    })
    return socket
}

export const subscribeOpenOrders = (callback) => {
    if (!socket) return
    socket.on('open-orders', (data) => {
        callback(null, data)
    })
}

export const subscribeFinishedOrders = (callback) => {
    if (!socket) return
    socket.on('finished-orders', (data) => {
        callback(null, data)
    })
}
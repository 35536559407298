import React from "react";
import { Suspense } from "react";
import { useLoaderData, useOutlet, Await } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";

import { Alert, AlertIcon } from "@chakra-ui/react";

import { AuthProvider } from "../context/AuthContext";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { userPromise } = useLoaderData();

  return (
    <Suspense
      fallback={
        <></>
        // <Box
        //   height="100vh"
        //   width="100%"
        //   display="flex"
        //   justifyContent="center"
        //   alignItems="center"
        // >
        //   <Spinner />
        // </Box>
      }
    >
      <Await
        resolve={userPromise}
        errorElement={
          <Alert status="error">
            <AlertIcon />
            Something went wrong
          </Alert>
        }
        children={(user) => (
          <AuthProvider userData={user}>{outlet}</AuthProvider>
        )}
      />
    </Suspense>
  );
};

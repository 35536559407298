import React from "react";
import RecentTrades from "../Market/RecentTrades";
import { Box } from "@chakra-ui/react";

const Dashboard = () => {
  return (
    <>
      <RecentTrades />
    </>
  );
};

export default Dashboard;

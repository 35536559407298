import React, { useState, useEffect } from 'react';
import moment from 'moment';
import smartTruncate from 'smart-truncate';
import { toTron } from '../../common/utils';
import { BANDWIDTH, ENERGY } from '../../common/utils/constants';
import { cancelOrder } from '../../common/api';
import {
  Box,
  Button,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  ModalFooter,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  useColorModeValue,
  Tag,
  Tooltip,
  TagLabel,
} from '@chakra-ui/react';
import isEmpty from 'is-empty';
import ModalComponent from '../../components/Modal';
import { ORDERSTATUS } from '../../common/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOrderHandler } from '../../redux/orderSlice';

const MyOrders = () => {
  const dispatch = useDispatch();
  const { myOpenOrder } = useSelector((state) => state.order);
  const [cancelOrderData, setCancelOrderData] = useState([]);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const toast = useToast();

  const cancelOrderHandler = async (id) => {
    setIsCancelLoading(true);
    try {
      const res = await cancelOrder(id);
      if (res.status) {
        setIsCancelLoading(false);
        setIsCancelOpen(false);
        let status;
        if (activeTab === 0) {
          status = ORDERSTATUS.COMPLETED;
        } else if (activeTab === 1) {
          status = ORDERSTATUS.PENDING;
        } else if (activeTab === 2) {
          status = ORDERSTATUS.CANCELED;
        }
        dispatch(getUserOrderHandler(status));
        toast({
          title: res.data.message,
          position: 'top-right',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsCancelLoading(false);
      toast({
        title: error || `Something went wrong`,
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    let status;
    if (activeTab === 0) {
      status = ORDERSTATUS.COMPLETED;
    } else if (activeTab === 1) {
      status = ORDERSTATUS.PENDING;
    } else if (activeTab === 2) {
      status = ORDERSTATUS.CANCELED;
    }

    dispatch(getUserOrderHandler(status));
  }, [activeTab, dispatch]);

  return (
    <>
      <h2 className='title'>My Orders</h2>

      <Tabs
        mt='2'
        className='tw-card-2'
        backgroundColor={useColorModeValue('#fff', '#3e3d3c')}
      >
        <TabList>
          <Tab onClick={() => setActiveTab(0)}>My Orders</Tab>
          <Tab onClick={() => setActiveTab(1)}>My Open Orders</Tab>
          <Tab onClick={() => setActiveTab(2)}>My Cancelled Orders</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableContainer
              mt='5'
              py='5'
              className='shadow--outset'
              rounded='lg'
            >
              {!isEmpty(myOpenOrder) ? (
                <Table variant='unstyled' size={{ base: 'sm' }}>
                  <Thead>
                    <Tr>
                      <Th>Sr. No.</Th>
                      <Th>Resource</Th>
                      <Th>Price/day</Th>
                      <Th>Payout</Th>
                      <Th>Date</Th>
                      <Th>TXN ID</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {myOpenOrder?.map((e, index) => {
                      return (
                        <Tr key={e.txn_id + e._id}>
                          <Td>{index + 1}</Td>

                          <Td>
                            <Tag
                              size={'sm'}
                              key={'sm'}
                              variant='outline'
                              colorScheme={
                                e.resource_type === ENERGY ? 'red' : 'teal'
                              }
                            >
                              <Tooltip
                                label={
                                  e.resource_type === ENERGY
                                    ? ENERGY
                                    : BANDWIDTH
                                }
                                placement='auto-start'
                              >
                                <TagLabel>
                                  {e.resource_type === ENERGY ? 'E' : 'B'}
                                </TagLabel>
                              </Tooltip>
                            </Tag>{' '}
                            {e.resource_amount_ordered}
                          </Td>

                          <Td>{e.price_per_day} SUN</Td>
                          <Td>{toTron(e.order_amount)} TRX</Td>
                          <Td>
                            {moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')}
                          </Td>
                          <Td>
                            <Link
                              href={`https://tronscan.org/#/transaction/${e.txn_id}`}
                              isExternal
                            >
                              {smartTruncate(e.txn_id, 18, { position: 8 })}
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <h3>No Data Available</h3>
                </div>
              )}
            </TableContainer>
          </TabPanel>

          <TabPanel>
            <TableContainer
              mt='5'
              py='5'
              className='shadow--outset'
              rounded='lg'
            >
              {!isEmpty(myOpenOrder) ? (
                <Table variant='unstyled' size={{ base: 'sm' }}>
                  <Thead>
                    <Tr>
                      <Th>Sr. No.</Th>
                      <Th>Resource</Th>
                      <Th>Price/day</Th>
                      <Th>Payout</Th>
                      <Th>Date</Th>
                      <Th>TXN ID</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {myOpenOrder?.map((e, index) => {
                      return (
                        <Tr key={e.txn_id + e._id}>
                          <Td>{index + 1}</Td>

                          <Td>
                            <Tag
                              size={'sm'}
                              key={'sm'}
                              variant='outline'
                              colorScheme={
                                e.resource_type === ENERGY ? 'red' : 'teal'
                              }
                            >
                              <Tooltip
                                label={
                                  e.resource_type === ENERGY
                                    ? ENERGY
                                    : BANDWIDTH
                                }
                                placement='auto-start'
                              >
                                <TagLabel>
                                  {e.resource_type === ENERGY ? 'E' : 'B'}
                                </TagLabel>
                              </Tooltip>
                            </Tag>{' '}
                            {e.resource_amount_ordered}
                          </Td>

                          <Td>{e.price_per_day} SUN</Td>
                          <Td>{toTron(e.order_amount)} TRX</Td>
                          <Td>
                            {moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')}
                          </Td>
                          <Td>
                            <Link
                              href={`https://tronscan.org/#/transaction/${e.txn_id}`}
                              isExternal
                            >
                              {smartTruncate(e.txn_id, 18, { position: 8 })}
                            </Link>
                          </Td>
                          <Td>
                            <Button
                              onClick={() => {
                                setCancelOrderData(e);
                                setIsCancelOpen(true);
                              }}
                            >
                              Cancel
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <h3>No Data Available</h3>
                </div>
              )}
            </TableContainer>
          </TabPanel>

          <TabPanel>
            <TableContainer
              mt='5'
              py='5'
              className='shadow--outset'
              rounded='lg'
            >
              {!isEmpty(myOpenOrder) ? (
                <Table variant='unstyled' size={{ base: 'sm' }}>
                  <Thead>
                    <Tr>
                      <Th>Sr. No.</Th>
                      <Th>Resource</Th>
                      <Th>Price/day</Th>
                      <Th>Payout</Th>
                      <Th>Date</Th>
                      <Th>TXN ID</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {myOpenOrder?.map((e, index) => {
                      return (
                        <Tr key={e.txn_id + e._id}>
                          <Td>{index + 1}</Td>

                          <Td>
                            <Tag
                              size={'sm'}
                              key={'sm'}
                              variant='outline'
                              colorScheme={
                                e.resource_type === ENERGY ? 'red' : 'teal'
                              }
                            >
                              <Tooltip
                                label={
                                  e.resource_type === ENERGY
                                    ? ENERGY
                                    : BANDWIDTH
                                }
                                placement='auto-start'
                              >
                                <TagLabel>
                                  {e.resource_type === ENERGY ? 'E' : 'B'}
                                </TagLabel>
                              </Tooltip>
                            </Tag>{' '}
                            {e.resource_amount_ordered}
                          </Td>

                          <Td>{e.price_per_day} SUN</Td>
                          <Td>{toTron(e.order_amount)} TRX</Td>
                          <Td>
                            {moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')}
                          </Td>
                          <Td>
                            <Link
                              href={`https://tronscan.org/#/transaction/${e.txn_id}`}
                              isExternal
                            >
                              {smartTruncate(e.txn_id, 18, { position: 8 })}
                            </Link>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <h3>No Data Available</h3>
                </div>
              )}
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalComponent
        isOpen={isCancelOpen}
        onClose={() => setIsCancelOpen(false)}
        title='Cancel Order'
      >
        <Box className='form_body'>
          <TableContainer>
            <Table variant='unstyled' size='sm'>
              <Tbody>
                <Tr>
                  <Td>Left Energy</Td>
                  <Td>{cancelOrderData.resource_amount}</Td>
                </Tr>
                <Tr>
                  <Td>Return TRX</Td>
                  <Td>{toTron(cancelOrderData.order_amount) - 1} TRX</Td>
                </Tr>
                <Tr>
                  <Td>Cancelation Fee</Td>
                  <Td>1 TRX</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <ModalFooter>
            <Button
              className='primary'
              mr={5}
              onClick={() => cancelOrderHandler(cancelOrderData._id)}
              disabled={isCancelLoading}
              isLoading={isCancelLoading}
            >
              Cancel Order
            </Button>
            <Button
              className='secondary'
              onClick={() => setIsCancelOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </Box>
      </ModalComponent>
    </>
  );
};

export default MyOrders;

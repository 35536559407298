import isEmpty from "is-empty";
import { BANDWIDTH, ENERGY } from "./constants";

export const ascii_to_hex = (str) => {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join("");
};

export const toSun = (value) => {
  return value * 1000000;
};

export const toTron = (value) => {
  return value / 1000000;
};


export const calculateResourceTron = (resource_type, resource_amount, accountDetails) => {
  if (isEmpty(accountDetails)) return;
  const {
    totalEnergyLimit,
    totalEnergyWeight,
    totalNetLimit,
    totalNetWeight,
  } = accountDetails.bandwidth;
  var amount = 0;
  if (resource_type === ENERGY)
    amount = (resource_amount * totalEnergyWeight) / totalEnergyLimit;
  if (resource_type === BANDWIDTH)
    amount = (resource_amount * totalNetWeight) / totalNetLimit;
  return amount.toFixed(2);
};


export const calculateOrderAmount = (amount, freezeDuration, value) => {
  return amount * freezeDuration * value;
};
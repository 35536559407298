import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiUsers,
  FiChevronDown,
} from "react-icons/fi";
import { CgLogOut } from "react-icons/cg";
import { Outlet, useNavigate, NavLink, useLocation} from "react-router-dom";
import AppNavigation from "../shared/AppNavigation";
import DashboardNavbar from "../shared/DashboardNavbar";
import { BiMoneyWithdraw } from "react-icons/bi"
import { MdPendingActions } from "react-icons/md"

const LinkItems = [
  { name: "Home", icon: FiHome, path: "/", },
  { name: "Dashboard", icon: FiMenu, path: "/dashboard" },
  { name: "My Orders", icon: BiMoneyWithdraw, path: "/myorders" },
  { name: "Referral", icon: FiUsers, path: "/referral" },
  { name: "API Keys", icon: MdPendingActions, path: "/API-keys" },
];

const SidebarContent = ({ onClose, ...rest }) => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const disconnectWallet = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Link href="/">
          {colorMode === "light" ? (
            <img
              src={require("../assets/img/logo/logo_dark.png")}
              alt="Logo"
              width={"180px"}
            />
          ) : (
            <img
              src={require("../assets/img/logo/logo_light.png")}
              alt="Logo"
              width={"180px"}
            />
          )}
        </Link>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Box className="side-menu">
      <Box>
        {LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon} path={link.path}>
            {link.name}
          </NavItem>
        ))}
      </Box>
        <Box className="bottom-menu">
          <NavItem onClick={disconnectWallet} icon={CgLogOut}>
            Logout
          </NavItem>
        </Box>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, path, ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  return (
    <NavLink  className={isActive ? "active" : ""}
    to={path}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#f70112",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  );
};

const DashboardOutlet = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <DashboardNavbar />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Box boxShadow="base" backgroundColor={useColorModeValue("#fff", "#3e3d3c")} padding={5}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardOutlet;

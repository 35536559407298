import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tr,
  Text,
  useToast,
  Alert,
  AlertIcon,
  Table,
} from '@chakra-ui/react';
import CustomInput from '../../../components/Input';
import { BANDWIDTH, ENERGY } from '../../../common/utils/constants';
import { toTron } from '../../../common/utils';
import PlusMinusInput from '../../../components/PlusMinusInput';
import ModalComponent from '../../../components/Modal';
import isEmpty from 'is-empty';
import {
  getAccountV2,
  getTransactionResource,
  transferHandler,
} from '../../../common/api';
import { useSelector } from 'react-redux';

export default function Order(props) {
  const { onClose, isOpen } = props;

  const { accountAddress, accountBalance } = useSelector(
    (state) => state.account
  );
  const [isWalletBalanceSufficient, setIsWalletBalanceSufficient] =
    useState(false);
  const [accountActive, setAccountActive] = useState(true);
  const [transactionResource, setTransactionResource] = useState({});
  const [resourceType, setResourceType] = useState(ENERGY);
  const toast = useToast();
  const [orderLoader, setOrderLoader] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    address: accountAddress,
    amount: 1000000,
    pricePerEnergyDay: 50,
    pricePerBandwidthDay: 560,
    orderAmount: 0,
  });
  const [energyPrices, setEnergyPrices] = useState({
    minEnergyPrice: 25,
    maxEnergyPrice: 280,
  });
  const [bandwidthPrices, setBandwidthPrices] = useState({
    minBandwidthPrice: 500,
    maxBandwidthPrice: 1000,
  });

  const calculateOrderAmount = (amount, freezeDuration, value) => {
    return amount * freezeDuration * value;
  };

  useEffect(() => {
    getTransactionResource()
      .then((value) => {
        setTransactionResource(value);
        setOrderDetails((prev) => ({
          ...prev,
          pricePerEnergyDay: value?.Min_Energy_Price_Per_Day,
          pricePerBandwidthDay: value?.Min_Bandwidth_Price_Per_Day,
        }));
        setEnergyPrices((prev) => ({
          ...prev,
          minEnergyPrice: value?.Min_Energy_Price_Per_Day,
        }));
        setBandwidthPrices((prev) => ({
          ...prev,
          minBandwidthPrice: value?.Min_Bandwidth_Price_Per_Day,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [resourceType]);

  const updateOrderAmount = useCallback(
    (amount, freezeDuration, pricePerDay) => {
      const orderAmount = calculateOrderAmount(
        amount,
        freezeDuration,
        pricePerDay
      );
      setOrderDetails((prev) => ({ ...prev, orderAmount }));
    },
    []
  );

  useEffect(() => {
    if (resourceType === ENERGY) {
      updateOrderAmount(
        orderDetails.amount,
        transactionResource.Freeze_Duration,
        orderDetails.pricePerEnergyDay
      );
    } else {
      updateOrderAmount(
        orderDetails.amount,
        transactionResource.Freeze_Duration,
        orderDetails.pricePerBandwidthDay
      );
    }
  }, [
    orderDetails.amount,
    orderDetails.pricePerBandwidthDay,
    orderDetails.pricePerEnergyDay,
    resourceType,
    transactionResource,
    updateOrderAmount,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (accountAddress)
      setOrderDetails({ ...orderDetails, address: accountAddress });
  }, [accountAddress]);

  useEffect(() => {
    accountBalance <= toTron(orderDetails.orderAmount)
      ? setIsWalletBalanceSufficient(true)
      : setIsWalletBalanceSufficient(false);
  }, [orderDetails]);

  const updatePricePerDay = (value, name) => {
    const orderAmount = calculateOrderAmount(orderDetails.amount, 3, value);
    setOrderDetails((prev) => ({
      ...prev,
      [name]: value,
      orderAmount: orderAmount,
    }));
  };
  const handleBandwidthChange = (e) => {
    if (isEmpty(accountAddress)) return;
    const { name, value } = e.target;
    setOrderDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleEnergyAmountChange = (e) => {
    const { value } = e.target;
    updateOrderAmount(
      value,
      transactionResource.Freeze_Duration,
      orderDetails.pricePerEnergyDay
    );
    setOrderDetails((prev) => ({
      ...prev,
      amount: value,
    }));
  };

  const handleBandwidthAmountChange = (e) => {
    const { value } = e.target;
    updateOrderAmount(
      value,
      transactionResource.Freeze_Duration,
      orderDetails.pricePerBandwidthDay
    );
    setOrderDetails((prev) => ({
      ...prev,
      amount: value,
    }));
  };

  const orderHandler = async (order, resourceType) => {
    setOrderLoader(true);
    try {
      const res = await transferHandler(order, resourceType, accountAddress);
      toast({
        title: `Transaction completed successfully`,
        position: 'top-right',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setResourceType(ENERGY);
    } catch (error) {
      toast({
        title: error || `Something went wrong`,
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setOrderLoader(false);
    }
  };

  const getDiscountPercentage = () => {
    let ourPricePerDay =
      resourceType === ENERGY
        ? orderDetails.pricePerEnergyDay
        : orderDetails.pricePerBandwidthDay;
    let marketPricePerDay =
      resourceType === ENERGY
        ? energyPrices.maxEnergyPrice
        : bandwidthPrices.maxBandwidthPrice;
    let actualPrice = marketPricePerDay * 3 * orderDetails.amount;
    let ourPrice = ourPricePerDay * 3 * orderDetails.amount;
    let discountPercentage = ((actualPrice - ourPrice) / actualPrice) * 100;
    return discountPercentage.toFixed();
  };

  const validateAccount = async (address) => {
    const data = getAccountV2(address)
      .then((res) => {
        setAccountActive(res.data.activated);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      title={`BUY ${resourceType}`}
    >
      <Box className='form_body'>
        <Tabs>
          {/* <TabList justifyContent="center">
            {
              tronResources?.resource_type_status?.resource_type_paused === ENERGY &&
              <Tab
                className=" "
                onClick={() => {
                  setResourceType(ENERGY);
                  setOrderDetails((prev) => ({
                    ...prev,
                    pricePerEnergyDay: 50,
                    amount: 1000000,
                  }));
                }}
              >
                Energy
              </Tab>
            }
            {
              tronResources?.resource_type_status?.resource_type_paused === BANDWIDTH &&
              <Tab
                className=" "
                ml="3"
                onClick={() => {
                  setResourceType(BANDWIDTH);
                  setOrderDetails((prev) => ({
                    ...prev,
                    pricePerEnergyDay: 560,
                    amount: 10000,
                  }));
                }}
              >
                Bandwidth
              </Tab>
            }
          </TabList> */}
          <TabPanels pt='3'>
            <TabPanel>
              <Box mb='5'>
                <CustomInput
                  label='Energy address'
                  placeholder='Energy address'
                  value={orderDetails.address}
                  name='address'
                  type='text'
                  onChange={handleChange}
                  onBlur={() => validateAccount(orderDetails.address)}
                  isInvalid={!accountActive}
                />
                {!accountActive && (
                  <Text fontSize='xs' color='crimson' mt='1'>
                    Address is not activated.{' '}
                  </Text>
                )}
              </Box>
              <Box mb='5'>
                <CustomInput
                  label='Energy amount'
                  placeholder='Energy amount'
                  isInvalid={isWalletBalanceSufficient}
                  errorBorderColor='crimson'
                  value={orderDetails.amount}
                  name='amount'
                  type='number'
                  onChange={handleEnergyAmountChange}
                />
                {isWalletBalanceSufficient && (
                  <Text fontSize='xs' color='crimson' mt='1'>
                    Insufficient Wallet Balance
                  </Text>
                )}
                {orderDetails.amount <
                  transactionResource?.Minimum_Energy_order && (
                  <Text fontSize='xs' color='crimson' mt='1'>
                    Min Amount : {transactionResource?.Minimum_Energy_order}
                  </Text>
                )}
              </Box>
              <FormControl mb='3'>
                <FormLabel htmlFor='amount'>Price/day</FormLabel>
                <PlusMinusInput
                  updateState={(value, name) => updatePricePerDay(value, name)}
                  maxValue={energyPrices.maxEnergyPrice}
                  minValue={energyPrices.minEnergyPrice}
                  name='pricePerEnergyDay'
                  value={orderDetails.pricePerEnergyDay}
                />
              </FormControl>
              <p>Delegation duration: 3 Days</p>
              <Box
                p='1'
                mt='3'
                boxShadow='md'
                rounded={'lg'}
                border='1px solid #ccc'
              >
                <Table variant='unstyled' size='sm'>
                  <Tbody>
                    <Tr>
                      <Td width={'150px'}>Order amount</Td>
                      <Td>: {toTron(orderDetails.orderAmount)} TRX</Td>
                    </Tr>
                    <Tr>
                      <Td width={'150px'}>Savings</Td>
                      <Td>
                        :{' '}
                        <span className='green'>
                          {toTron(
                            orderDetails?.amount *
                              3 *
                              energyPrices.maxEnergyPrice -
                              orderDetails.orderAmount
                          )}{' '}
                          TRX ({getDiscountPercentage()}%)
                        </span>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td width={'150px'}>TXN Fee</Td>
                      <Td>
                        : 0 TRX{' '}
                        <Text fontSize='xs' as='i' color={'orange'}>
                          Free Forever
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              <Stack direction='row' spacing={4} mt='5'>
                <Button
                  className='primary'
                  width='100%'
                  isDisabled={
                    orderDetails.amount >=
                      transactionResource?.Minimum_Energy_order &&
                    !isWalletBalanceSufficient &&
                    !orderLoader &&
                    accountActive
                      ? false
                      : true
                  }
                  onClick={() => {
                    orderHandler(orderDetails, ENERGY);
                  }}
                  isLoading={orderLoader}
                >
                  Place Order
                </Button>
                <Button className='secondary' width='100%' onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </TabPanel>
            <TabPanel>
              <Box mb='5'>
                <CustomInput
                  label='Bandwidth address'
                  placeholder='Bandwidth address'
                  value={orderDetails.address}
                  name='address'
                  onChange={handleBandwidthChange}
                  type='text'
                />
              </Box>
              <Box mb='5'>
                <CustomInput
                  label='Bandwidth amount'
                  placeholder='Bandwidth amount'
                  isInvalid={isWalletBalanceSufficient}
                  errorBorderColor='crimson'
                  value={orderDetails.amount}
                  name='amount'
                  onChange={handleBandwidthAmountChange}
                  type='number'
                />
                {isWalletBalanceSufficient && (
                  <Text fontSize='xs' color='crimson' mt='1'>
                    Insufficient Wallet Balance
                  </Text>
                )}
                {orderDetails.amount <
                  transactionResource?.Minimum_Bandwidth_order && (
                  <Text fontSize='xs' color='crimson' mt='1'>
                    Min Amount : {transactionResource?.Minimum_Bandwidth_order}{' '}
                  </Text>
                )}
              </Box>
              <FormControl mb='3'>
                <FormLabel htmlFor='amount'>Price/day</FormLabel>
                <PlusMinusInput
                  updateState={(value, name) => updatePricePerDay(value, name)}
                  maxValue={bandwidthPrices.maxBandwidthPrice}
                  minValue={bandwidthPrices.minBandwidthPrice}
                  name='pricePerBandwidthDay'
                  value={orderDetails.pricePerBandwidthDay}
                />
              </FormControl>
              <p>Delegation duration: 3 Days</p>
              <Box
                p='5'
                mt='3'
                boxShadow='md'
                rounded={'lg'}
                border='1px solid #ccc'
              >
                <Table variant='unstyled' size='sm'>
                  <Tbody>
                    <Tr>
                      <Td width={'150px'}>Order amount</Td>
                      <Td>: {toTron(orderDetails.orderAmount)} TRX</Td>
                    </Tr>
                    <Tr>
                      <Td width={'150px'}>Savings</Td>
                      <Td>
                        :{' '}
                        <span className='green'>
                          {toTron(
                            orderDetails?.amount *
                              3 *
                              bandwidthPrices.maxBandwidthPrice -
                              orderDetails.orderAmount
                          )}{' '}
                          TRX ({getDiscountPercentage()}%)
                        </span>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td width={'150px'}>TXN Fee</Td>
                      <Td>
                        : 0 TRX{' '}
                        <Text fontSize='xs' as='i' color={'orange'}>
                          Free Forever
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
              <Stack direction='row' spacing={4} mt='5'>
                <Button
                  className='primary'
                  width='100%'
                  isDisabled={
                    orderDetails.amount >=
                      transactionResource?.Minimum_Bandwidth_order &&
                    !isWalletBalanceSufficient &&
                    !orderLoader
                      ? false
                      : true
                  }
                  onClick={() => orderHandler(orderDetails, BANDWIDTH)}
                  isLoading={orderLoader}
                >
                  Place Order
                </Button>
                <Button className='secondary' width='100%' onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ModalComponent>
  );
}

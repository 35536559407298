import axios from "axios";
import Axios from "axios";
import isEmpty from "is-empty";
import { ascii_to_hex, calculateResourceTron, toSun } from "../utils";
import {
  NONCE_API,
  TRANSACTION_RESOURCE_API,
  USER_LOGIN_API,
  TRADE_API,
  ORDER_API,
  TO_ADDRESS,
  TRANSACTION_API,
  ACCOUNT_DETAILS,
  ENERGY,
  FILL_OPEN_ORDER_API,
  BANDWIDTH,
} from "../utils/constants";

const axiosInstance = axios.create({});

//create new promise
export const getTransactionResource = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await axios.get(TRANSACTION_RESOURCE_API);
      resolve(data.data.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAccountV2 = (accountAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await axios.get(
        process.env.REACT_APP_ACCOUNTV2_DETAILS + `?address=${accountAddress}`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

const postTransaction = async (url, payload) => {
  const unsignedTxn = await axiosInstance.post(url, payload);
  if (unsignedTxn) {
    const signedTxn = await window.tronWeb.trx.sign(unsignedTxn.data);
    return await axiosInstance.post(
      TRANSACTION_API + `broadcasttransaction`,
      signedTxn
    );
  }
  return "NO_SIGN";
};

export const fillOrder = async (order, accountAddress, accountDetails) => {
  try {
    const { public_address, _id, resource_type, resourceAmount } = order;
    let resBroadcast;
    let resourceAvailable =
      resource_type === ENERGY
        ? accountDetails.maxDelegatableEnergy
        : accountDetails.maxDelegatableBandwidth;
    let requiredResource = resourceAmount - resourceAvailable;
    let frozen_balance = Math.ceil(
      calculateResourceTron(resource_type, requiredResource, accountDetails)
    );

    const payload = {
      frozen_balance: toSun(frozen_balance),
      frozen_duration: 3,
      owner_address: window.tronWeb.address.toHex(accountAddress),
      receiver_address: window.tronWeb.address.toHex(public_address),
      resource: resource_type,
    };

    const delegateResourcePayload = {
      owner_address: accountAddress,
      receiver_address: public_address,
      balance: toSun(
        Math.ceil(
          calculateResourceTron(resource_type, resourceAmount, accountDetails)
        )
      ),
      resource: resource_type,
      lock: true,
      visible: true,
    };

    // check if undelegated energy/bandwidth is available
    if (resourceAvailable > resourceAmount) {
      resBroadcast = await postTransaction(
        TRANSACTION_API + `delegateresource`,
        delegateResourcePayload
      );
    } else {
      resBroadcast = await postTransaction(
        TRANSACTION_API + `freezebalancev2`,
        payload
      );
      if (resBroadcast.status === 200) {
        resBroadcast = await postTransaction(
          TRANSACTION_API + `delegateresource`,
          delegateResourcePayload
        );
      } else {
        throw new Error("Error in freeze balance transaction");
      }
    }
    if (resBroadcast === "NO_SIGN") {
      return "ERROR";
    }
    const resOrderPayload = {
      filled_by_address: accountAddress,
      trx_hash_stake: resBroadcast.data.txid,
      txn_id_order: _id,
    };

    const resOrder = await axios.post(FILL_OPEN_ORDER_API, resOrderPayload);
    return resOrder;
  } catch (error) {
    return error.message;
  }
};

export const transferHandler = async (order, resourceType, accountAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        address,
        amount,
        orderAmount,
        pricePerEnergyDay,
        pricePerBandwidthDay,
      } = order;
      const unsignedTxn = await window.tronWeb.transactionBuilder.sendTrx(
        TO_ADDRESS,
        orderAmount,
        accountAddress
      );

      const signedTxn = await window.tronWeb.trx.sign(unsignedTxn);
      const resBroadcast = await axiosInstance.post(
        TRANSACTION_API + `broadcasttransaction`,
        signedTxn
      );
      if (resBroadcast.status === 200) {
        let price_per_day =
          resourceType === ENERGY
            ? parseInt(pricePerEnergyDay)
            : parseInt(pricePerBandwidthDay);
        const payloadOrder = {
          receiver: address,
          amount: orderAmount,
          resource_type: resourceType,
          trx_hash: resBroadcast.data.txid,
          price_per_day: price_per_day,
          freeze_duration: 3,
          resource_amount: amount,
        };
        try {
          const resOrder = await axios.post(ORDER_API, payloadOrder);
          resolve(resOrder.message);
        } catch (error) {
          reject(error.response.data.message || error.message);
        }
      } else {
        reject("Somthing went wrong");
      }
    } catch (error) {
      reject(error);
    }
  });
};

// getcandelegatedmaxsize
export const getCanDelegatedMaxSize = (address, type) => {
  const payload = {
    owner_address: address,
    type: type === ENERGY ? 1 : 0,
    visible: true,
  };
  return new Promise(async (resolve, reject) => {
    if (isEmpty(address)) reject("Account address is required");
    try {
      const res = await axiosInstance.post(
        process.env.REACT_APP_TRANSACTION_API + `getcandelegatedmaxsize`,
        payload
      );
      const data = res.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

//GET delegated list
export const getMyFreezeList = (accountAddress, type) => {
  return new Promise(async (resolve, reject) => {
    if (isEmpty(accountAddress)) reject("Account address is required");
    try {
      const updatedFreeListApi =
        ACCOUNT_DETAILS +
        `/resourcev2?limit=100&start=0&type=${type}&address=${accountAddress}`;
      const res = await axios.get(updatedFreeListApi);
      if (res.status === 200) {
        resolve(res.data.data);
      }
    } catch (error) {
      reject(error);
    }
  });
};

// undelegateresource
export const undelegateresource = async (
  receiverAddress,
  resource,
  balance,
  accountAddress
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const payload = {
        owner_address: window.tronWeb.address.toHex(accountAddress),
        receiver_address: window.tronWeb.address.toHex(receiverAddress),
        balance: parseInt(balance),
        resource: resource == 1 ? ENERGY : BANDWIDTH,
      };
      const undelegateresourceAPI =
        process.env.REACT_APP_TRANSACTION_API + "undelegateresource";
      const res = await Axios.post(undelegateresourceAPI, payload);
      const signedTxn = await window.tronWeb.trx.sign(res.data);
      const resBroadcast = await axiosInstance.post(
        TRANSACTION_API + `broadcasttransaction`,
        signedTxn
      );
      resolve(resBroadcast.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTrades = (status, page) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        TRADE_API + status + `?page=${page}&limit=10`
      );
      const data = res.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getOpenOrders = (page) => {
  const filterBy = { sort_param: "price", order: "desc" };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        `open-order?page=${page}&limit=10`,
        filterBy
      );
      const data = res.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserOrder = (publicAddress, status) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`orders/${publicAddress}/${status}`);
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getNonce = async (publicAddress, ref = null) => {
  return new Promise((resolve, reject) => {
    if (isEmpty(publicAddress)) return;
    axios
      .get(`${NONCE_API}${publicAddress}`)
      .then((res) => {
        localStorage.setItem("nonce", res?.data?.nonce);
        resolve(res?.data?.nonce);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cancelOrder = async (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/order/${id}/cancel`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const userLogin = async (publicAddress, ref = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      let nonce = await getNonce(publicAddress);
      let text = `I am signing my one-time nonce: ${nonce}`;
      const sign = window.tronWeb.trx.sign(ascii_to_hex(text));
      let data = {
        public_address: publicAddress,
        signature: "",
      };

      Promise.resolve(sign).then(
        function (value) {
          data.signature = value;
          axios
            .post(USER_LOGIN_API, data)
            .then((res) => {
              resolve(res.data.data);
            })
            .catch((err) => {
              reject(err);
            });
        },
        function (value) {
          return;
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};

// sign up api call
export const Signupcall = async (publicAddress, ref = null) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`tron/user/signup`, {
        publicAddress,
        referalCode: ref,
      })
      .then((res) => {
        resolve(res);

        // this.setState({ buyTicket: res.data.buy_Ticket })
        // setBuyTicket(res.data.buy_Ticket)
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Unfreez By Admin
export const unfreezeCompletedOrders = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`unfreeze-token`);
      const data = res;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
// By Admin
export const fillOrderByAdmin = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`fill-order`);
      const data = res;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const processPendingIncomeByAdmin = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`settle-tron`);
      const data = res;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateOrderResourceListByAdmin = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        process.env.REACT_APP_ADMIN_URL + `pause-resource`,
        { paused: value }
      );
      const data = res;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateProviderList = (values) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        process.env.REACT_APP_ADMIN_URL + `tron-account`,
        values
      );
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeCommunityMember = (values) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.delete(
        process.env.REACT_APP_ADMIN_URL + `tron-account`,
        { data: { tron_address: values } }
      );
      const data = res.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateOpenOrderStatus = (value) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        process.env.REACT_APP_ADMIN_URL + `open-order`,
        { order_status: value }
      );
      const data = res.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

// Admin
export const getTronProvidersList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_ADMIN_URL + `tron-account?page=1&limit=10`
      );
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

//Get Platform Stats Admin
export const getPlatformStats = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // Get the current date
      const currentDate = new Date();

      // Get the start date of the current month
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // Format the start and end dates
      const startDateFormatted = startOfMonth.toISOString().split("T")[0];
      const endDateFormatted = currentDate.toISOString().split("T")[0];

      const res = await axios.get(
        process.env.REACT_APP_ADMIN_URL +
          `platform-stats?fromDate=${startDateFormatted}&toDate=${endDateFormatted}`
      );
      const data = res;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

//Get total no of reclaimble orders Admin
export const undelegatableCount = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_ADMIN_URL + `undelegatable-count`
      );
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Refferal APIs

export const getMyRefferalDetails = (publicAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`/my-referral/${publicAddress}`);
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMyRefferalBy = (publicAddress) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(`/referred-by/${publicAddress}`);
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createRefferal = (publicAddress, referredBy) => {
  let payload = {
    user_address: publicAddress,
    referred_by: referredBy,
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`/add-referral`, payload);
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const withdrawRefferalReward = (publicAddress, withdraw_amount) => {
  let payload = { user_address: publicAddress, withdraw_amount };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`/withdraw-referral`, payload);
      const data = res.data.data;
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

import { createSlice } from '@reduxjs/toolkit';
import { toTron } from '../common/utils';
import {
  createRefferal,
  getAccountV2,
  getCanDelegatedMaxSize,
  getMyFreezeList,
  getTransactionResource,
} from '../common/api';
import axios from 'axios';
import {
  ACCOUNT_DETAILS,
  BANDWIDTH,
  ENERGY,
  TRONSCAN_API_KEY,
} from '../common/utils/constants';

const initialState = {
  isLoading: false,
  accountDetails: null,
  accountAddress: '',
  accountBalance: '',
  referralAddress: '',
  noWalletDetect: false,
  totalFrozenBalance: 0,
  resourceDetails: null,
  isDataLoading: false,
  isAdmin: false,
  tronResources: null,
  myFreezeList: [],
  isDataLoaded: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsDataLoading(state, action) {
      state.isDataLoading = action.payload;
    },
    setAccountDetails(state, action) {
      state.accountDetails = action.payload;
    },
    setAccountAddress(state, action) {
      state.accountAddress = action.payload;
    },
    setAccountBalance(state, action) {
      state.accountBalance = action.payload;
    },
    setReferralAddress(state, action) {
      state.referralAddress = action.payload;
    },
    setNoWalletDetect(state, action) {
      state.noWalletDetect = action.payload;
    },
    setTotalFrozenBalance(state, action) {
      state.totalFrozenBalance = action.payload;
    },
    setResourceDetails(state, action) {
      state.resourceDetails = action.payload;
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setTronResources(state, action) {
      state.tronResources = action.payload;
    },
    setMyFreezeList(state, action) {
      state.myFreezeList = action.payload;
    },
    setIsDataLoaded(state, action) {
      state.isDataLoaded = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setAccountDetails,
  setAccountAddress,
  setAccountBalance,
  setReferralAddress,
  setNoWalletDetect,
  setTotalFrozenBalance,
  setResourceDetails,
  setIsDataLoading,
  setIsAdmin,
  setTronResources,
  setMyFreezeList,
  setIsDataLoaded,
} = accountSlice.actions;

export default accountSlice.reducer;

export const getBalance = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    //if wallet installed and logged , getting TRX token balance
    if (window?.tronWeb && window?.tronWeb?.ready) {
      let walletBalances = await window.tronWeb.trx.getAccount(
        window.tronWeb.defaultAddress.base58
      );
      dispatch(setIsLoading(false));
      return walletBalances;
    } else {
      return 0;
    }
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const getWalletDetails = () => async (dispatch) => {
  try {
    dispatch(setIsDataLoading(true));
    if (window.tronWeb) {
      dispatch(setNoWalletDetect(false));
      //checking if wallet injected
      if (window.tronWeb.ready) {
        dispatch(getBalance()).then((res) => {
          dispatch(setAccountBalance(toTron(res.balance)));
          dispatch(setIsDataLoading(false));
          dispatch(setIsDataLoaded(true));
        });

        // onSubmitTotalFrozenBalance(toTron(tempFrozenBalance));
        //we have wallet and we are logged in
        const tempAddress = window.tronWeb.defaultAddress.base58;
        dispatch(setAccountAddress(tempAddress));
        localStorage.setItem('address', tempAddress);

        //referral
        const urlParams = new URLSearchParams(window.location.search);
        const referralAddress = urlParams.get('ref'); // Store referral

        if (referralAddress) {
          await createRefferal(
            window.tronWeb.defaultAddress.base58,
            referralAddress
          );
        } else {
          // Handle admin's referral if needed
          //  dispatch(handleAdminReferral(tempAddress));
        }
      } else {
        //we have wallet but not logged in
        // setMyMessage(<h3>WALLET DETECTED PLEASE LOGIN</h3>);

        dispatch(setAccountAddress(''));
        dispatch(setAccountBalance(0));
        dispatch(setIsDataLoading(false));
      }
    } else {
      //wallet is not detected at all
      dispatch(setNoWalletDetect(true));
      dispatch(setIsDataLoading(false));
    }
  } catch (error) {
    dispatch(setIsDataLoading(false));
  }
};

export const handleAdminReferral =
  (userAddress, referralAddress) => async (dispatch) => {
    try {
      if (!referralAddress) {
        referralAddress = 'TRCy8BponCNzrs8sN8YV48EEKVvcZM8tNn';
      }
      await createRefferal(userAddress, referralAddress);
    } catch (error) {}
  };

export const fetchAccountDetails = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const accountAddress = getState().account.accountAddress;
    const res = await axios.get(
      ACCOUNT_DETAILS + `?address=${accountAddress}`,
      {
        headers: {
          'TRON-PRO-API-KEY': TRONSCAN_API_KEY,
        },
      }
    );
    const res2 = await getAccountV2(accountAddress);
    const myFreezeList = await getMyFreezeList(accountAddress, 2);
    const myReceivedList = await getMyFreezeList(accountAddress, 1);

    if (res2.status === 200 && res2.data.activated) {
      let totalDelegateEnergy = 0;
      let totalDelegateBandwidth = 0;
      let totalReceivedEnergy = 0;
      let totalReceivedBandwidth = 0;
      for (let i = 0; i < myFreezeList.length; i++) {
        if (myFreezeList[i].resource === 1) {
          totalDelegateEnergy += myFreezeList[i].resourceValue;
        } else if (myFreezeList[i].resource === 0) {
          totalDelegateBandwidth += myFreezeList[i].resourceValue;
        }
      }
      for (let i = 0; i < myReceivedList.length; i++) {
        if (myReceivedList[i].resource === 1) {
          totalReceivedEnergy += myReceivedList[i].resourceValue;
        } else if (myReceivedList[i].resource === 0) {
          totalReceivedBandwidth += myReceivedList[i].resourceValue;
        }
      }
      const {
        totalEnergyLimit,
        totalEnergyWeight,
        totalNetLimit,
        totalNetWeight,
      } = res.data.bandwidth;
      let total_energy_limit = totalEnergyLimit;
      let total_net_limit = totalNetLimit;
      let total_energy_weight = totalEnergyWeight;
      let total_net_weight = totalNetWeight;
      let NetUsed = 0;
      let NetLimit = 0;
      let EnergyLimit = 0;

      if (res.data.NetUsed) {
        NetUsed = res.data.NetUsed;
      }
      if (res.data.NetLimit) {
        NetLimit = res.data.NetLimit;
      }
      if (res.data.EnergyLimit) {
        EnergyLimit = res.data.EnergyLimit;
      }

      let EnergyAvailableForDelegation = await getCanDelegatedMaxSize(
        accountAddress,
        ENERGY
      );
      let BandwidthAvailableForDelegation = await getCanDelegatedMaxSize(
        accountAddress,
        BANDWIDTH
      );

      let EnergyAmountAvailable =
        (window.tronWeb.fromSun(EnergyAvailableForDelegation.max_size) *
          total_energy_limit) /
        total_energy_weight;
      let BandAmountAvailable =
        (window.tronWeb.fromSun(BandwidthAvailableForDelegation.max_size) *
          total_net_limit) /
        total_net_weight;

      dispatch(
        setAccountDetails({
          ...res2.data,
          maxDelegatableBandwidth: BandAmountAvailable,
          maxDelegatableEnergy: EnergyAmountAvailable,
          totalDelegateEnergy: totalDelegateEnergy,
          totalDelegateBandwidth: totalDelegateBandwidth,
          totalReceivedEnergy: totalReceivedEnergy,
          totalReceivedBandwidth: totalReceivedBandwidth,
        })
      );

      dispatch(setAccountBalance(toTron(res.data.balance)));
      dispatch(
        setTotalFrozenBalance(
          toTron(res2.data.totalFrozen + res2.data.totalFrozenV2)
        )
      );
      dispatch(setResourceDetails(res.data.bandwidth));
    }
  } catch (error) {
    dispatch(setIsLoading(false));
  }
};

export const getTransactionResourceHandler =
  () => async (dispatch, getState) => {
    try {
      const res = await getTransactionResource();
      dispatch(setTronResources(res));
    } catch (error) {}
  };
export const checkAdmin = () => async (dispatch, getState) => {
  try {
    const accountAddress = getState().account.accountAddress;
    accountAddress === process.env.REACT_APP_ADMIN_ADDRESS
      ? dispatch(setIsAdmin(true))
      : dispatch(setIsAdmin(false));
  } catch (error) {}
};

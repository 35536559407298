import {
  Box,
  chakra,
  Grid,
  GridItem,
  SimpleGrid,
  Skeleton,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import isEmpty from 'is-empty';
import { useSelector } from 'react-redux';
import smartTruncate from 'smart-truncate';

export default function Head({
  accountAddress,
  accountBalance,
  totalFrozenBalance,
  isDataLoading,
  accountDetails,
}) {
  const { isDataLoaded } = useSelector((state) => state.account);
  return (
    <Box pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <div className='below-tab'>
        <chakra.h1
          textAlign={'center'}
          fontSize={'4xl'}
          fontWeight={'bold'}
          className='emboss'
        >
          Tronweb Rentals
        </chakra.h1>
        <chakra.h3 textAlign={'center'} fontSize={'3xl'} mb='25'>
          {!isDataLoaded ? (
            <Skeleton height='20px' mb='1' width='213px' margin='0 auto' />
          ) : (
            <Text className='text-primary' fontSize={'xl'}>
              <a
                href={`https://tronscan.org/#/address/${accountAddress}`}
                target='_blank'
              >
                {smartTruncate(accountAddress, 18, { position: 8 })}
              </a>
            </Text>
          )}
        </chakra.h3>
      </div>

      {/* Mobile View */}
      <GridItem
        className='head-info-box-single'
        px={{ base: 4, md: 8 }}
        py={'5'}
        backgroundColor={useColorModeValue('#fff', '#3e3d3c')}
      >
        <chakra.h3 textAlign={'left'} fontSize={'xl'}>
          <Text fontSize='lg' mb={3} as={'b'}>
            Account Details
          </Text>
          <div
            style={{
              padding: '10px',
              borderRadius: '4px',
              backgroundColor: useColorModeValue('#DFDFDF', '#303030'),
            }}
          >
            <Text fontSize='md'>{accountAddress}</Text>
            {!isDataLoaded ? (
              <Skeleton height='20px' width='50px' mt='2' ml='3' />
            ) : (
              <Text fontSize='sm'>
                Available:{' '}
                <span className='text-primary'>
                  {!isEmpty(accountBalance) ? accountBalance?.toFixed(2) : 0}{' '}
                  TRX
                </span>{' '}
                / Total:{' '}
                <span className='text-primary'>
                  {!isEmpty(accountBalance)
                    ? (accountBalance + totalFrozenBalance)?.toFixed(2)
                    : 0}{' '}
                  TRX
                </span>
              </Text>
            )}
          </div>

          <Grid mt={'3'} templateColumns='repeat(2, 1fr)' gap={2}>
            <GridItem w='100%'>
              <Text fontSize='sm'>
                Energy:
                {!isDataLoaded ? (
                  <Skeleton height='20px' width='50px' mt='2' ml='3' />
                ) : !isEmpty(accountDetails?.bandwidth?.energyRemaining) ? (
                  accountDetails?.bandwidth?.energyRemaining +
                  '/' +
                  accountDetails?.bandwidth?.energyLimit
                ) : (
                  0
                )}
              </Text>

              {accountDetails?.maxDelegatableEnergy > 1 ? (
                <Text fontSize='sm'>
                  Dlg:{' '}
                  {!isDataLoaded ? (
                    <Skeleton height='20px' width='50px' mt='2' ml='3' />
                  ) : (
                    parseInt(accountDetails?.maxDelegatableEnergy ?? 0)
                  )}{' '}
                </Text>
              ) : null}
            </GridItem>
            <GridItem w='100%'>
              <Text fontSize='sm'>
                Bandwidth:
                {!isDataLoaded ? (
                  <Skeleton height='20px' width='50px' mt='2' ml='3' />
                ) : !isEmpty(accountDetails?.bandwidth?.freeNetRemaining) ? (
                  accountDetails?.bandwidth?.netRemaining +
                  accountDetails?.bandwidth?.freeNetRemaining +
                  '/' +
                  (accountDetails?.bandwidth?.netLimit +
                    accountDetails?.bandwidth?.freeNetLimit)
                ) : (
                  0
                )}
              </Text>
              {accountDetails?.bandwidth?.netRemaining > 1 ? (
                <Text fontSize='sm'>
                  Dlg:{' '}
                  {!isDataLoaded ? (
                    <Skeleton height='20px' width='50px' mt='2' ml='3' />
                  ) : (
                    parseInt(accountDetails?.bandwidth?.netRemaining ?? 0)
                  )}
                </Text>
              ) : null}
            </GridItem>
          </Grid>
        </chakra.h3>
      </GridItem>

      <div className='head-info-box-multiple'>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
          <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            boxShadow='0 10px 20px rgba(0,0,0,.05)'
            border='0'
            borderRadius={8}
            width='100%'
            backgroundColor={useColorModeValue('#fff', '#3e3d3c')}
          >
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Available:{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : !isEmpty(accountBalance) ? (
                accountBalance.toFixed(2) + ' TRX'
              ) : (
                '0 TRX'
              )}
            </StatNumber>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Staked:{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : !isEmpty(totalFrozenBalance) ? (
                totalFrozenBalance.toFixed(2) + ' TRX'
              ) : (
                '0 TRX'
              )}
            </StatNumber>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Total:{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : !isEmpty(accountBalance) ? (
                (accountBalance + totalFrozenBalance).toFixed(2) + ' TRX'
              ) : (
                '0 TRX'
              )}
            </StatNumber>
          </Stat>
          <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            boxShadow='0 10px 20px rgba(0,0,0,.05)'
            border='0'
            borderRadius={8}
            width='100%'
            backgroundColor={useColorModeValue('#fff', '#3e3d3c')}
          >
            <StatLabel fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Energy:{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : !isEmpty(accountDetails?.bandwidth?.energyRemaining) ? (
                accountDetails?.bandwidth?.energyRemaining +
                '/' +
                accountDetails?.bandwidth?.energyLimit
              ) : (
                0
              )}
            </StatLabel>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Delegable :{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : (
                parseInt(accountDetails?.maxDelegatableEnergy ?? 0)
              )}
            </StatNumber>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Delegated :{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : (
                parseInt(accountDetails?.totalDelegateEnergy ?? 0)
              )}
            </StatNumber>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Received :{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : (
                parseInt(accountDetails?.totalReceivedEnergy ?? 0)
              )}
            </StatNumber>
          </Stat>
          <Stat
            px={{ base: 4, md: 8 }}
            py={'5'}
            boxShadow='0 10px 20px rgba(0,0,0,.05)'
            border='0'
            borderRadius={8}
            width='100%'
            backgroundColor={useColorModeValue('#fff', '#3e3d3c')}
          >
            <StatLabel fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Bandwidth:{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : !isEmpty(accountDetails?.bandwidth?.freeNetRemaining) ? (
                accountDetails?.bandwidth?.netRemaining +
                accountDetails?.bandwidth?.freeNetRemaining +
                '/' +
                (accountDetails?.bandwidth?.netLimit +
                  accountDetails?.bandwidth?.freeNetLimit)
              ) : (
                0
              )}
            </StatLabel>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Delegable :{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : (
                parseInt(accountDetails?.bandwidth?.netRemaining ?? 0)
              )}
            </StatNumber>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Delegated :{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : (
                parseInt(accountDetails?.totalDelegateBandwidth ?? 0)
              )}
            </StatNumber>
            <StatNumber fontSize={'16px'} fontWeight={'medium'} display='flex'>
              Received :{' '}
              {!isDataLoaded ? (
                <Skeleton height='20px' width='50px' mt='2' ml='3' />
              ) : (
                parseInt(accountDetails?.totalReceivedBandwidth ?? 0)
              )}
            </StatNumber>
          </Stat>
        </SimpleGrid>
      </div>
    </Box>
  );
}

import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaTelegram, FaTwitter } from "react-icons/fa";

const Logo = (props) => {
  return <Link to="/">Tronweb Rentals</Link>;
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("white", "gray.800")}
      color={useColorModeValue("gray.700", "gray.200")}
      borderTop={1}
      borderStyle={"solid"}
      borderColor={useColorModeValue("gray.200", "gray.900")}
    >
      <Container as={Stack} maxW={"1080px"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo color={useColorModeValue("gray.700", "white")} />
              <Text fontSize={"sm"}>© {new Date().getFullYear()} Tronweb Rentals. All rights reserved</Text>
            </Box>
          </Stack>
         
          <Stack align={"flex-start"}>
            {/* <ListHeader>Links</ListHeader>
            <Link href={"#"}>APIs</Link> */}
            <Link href={"/terms"}>Terms of Service</Link>
            {/* <Link href={"#"}>Privacy Policy</Link> */}
          </Stack>
          <Stack align={"flex-start"}>
            {/* <ListHeader>Tronweb</ListHeader>
            <Link href={"#"}>About us</Link> */}
            {/* <Link href={"#"}>Contact us</Link> */}
            <Stack direction={"row"} spacing={6}>
              <SocialButton label={"Twitter"} href={"#"}>
                <FaTwitter />
              </SocialButton>
             
              <SocialButton label={"Telegram"} href={"https://t.me/tronweb_resource"}>
                <FaTelegram />
              </SocialButton>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}

import React, { createContext, useContext, useEffect } from "react";
import { useState } from "react";
import { getTransactionResource } from "../common/api";
import { useMemo } from "react";
const AccountContext = createContext();

export function useAccount() {
  return useContext(AccountContext);
}

export default function AccountProvider({ children }) {
  const [accountAddress, setAccountAddress] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [noWalletDetect, setNoWalletDetect] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [totalFrozenBalance, setTotalFrozenBalance] = useState(0);
  const [accountDetails, setAccountDetails] = useState({});
  const [resourceDetails, setResourceDetails] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [freezeDuration, setFreezeDuration] = useState(3);
  const [tronResources, setTronResources] = useState("");

  const setAdmin = (flag) => {
    setIsAdmin(flag);
  };

  const values = useMemo(
    () => ({
      accountAddress,
      accountBalance,
      noWalletDetect,
      isDataLoading,
      totalFrozenBalance,
      accountDetails,
      resourceDetails,
      isAdmin,
      freezeDuration,
      tronResources,
      onSubmitAccountAddress: (data) => setAccountAddress(data),
      onSubmitAccountBalance: (data) => setAccountBalance(data),
      onSubmitNoWalletDetect: (flag) => setNoWalletDetect(flag),
      onSubmitDataLoading: (flag) => setIsDataLoading(flag),
      onSubmitTotalFrozenBalance: (amount) => setTotalFrozenBalance(amount),
      onSubmitAccountDetails: (data) => setAccountDetails(data),
      onSubmitResourceDetails: (data) => setResourceDetails(data),
      setAdmin: (data) => setAdmin(data),
      setTronResources: (data) => setTronResources(data),
    }),
    [
      accountAddress,
      accountBalance,
      accountDetails,
      freezeDuration,
      isAdmin,
      isDataLoading,
      noWalletDetect,
      resourceDetails,
      totalFrozenBalance,
      tronResources,
    ]
  );

  return (
    <AccountContext.Provider value={values}>{children}</AccountContext.Provider>
  );
}

import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Button,
  Link,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import smartTruncate from "smart-truncate";
import { useAccount } from "../context/AccountProvider";
import { useState } from "react";

const DashboardNavbar = ({ onOpen, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { accountAddress } = useAccount();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const disconnectWallet = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <HStack spacing={{ base: "0", md: "6" }}>
        <IconButton
          aria-label="Search"
          onClick={toggleColorMode}
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          backgroundColor="transparent"
        />
        <Flex alignItems={"center"}>
          <Button className="logged-in-btn" onClick={disconnectWallet}>
            <img src={require("../assets/img/tron.png")} alt="tron" />{" "}
            <Text>{smartTruncate(accountAddress, 18, { position: 8 })}</Text>
          </Button>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default DashboardNavbar;

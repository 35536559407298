import { Table, TableContainer, Tbody, Td, Th, Tr } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import { toSun, toTron } from '../../common/utils';

export default function AdminOverview() {
    const [adminData, setAdminData] = useState([]);
    // function 
    const Dataoverview = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let headers = { headers: { "Authorization": `Bearer ${localStorage.getItem("token")}` } }
                const res = await Axios.get(process.env.REACT_APP_ADMIN_URL + `dapp-data`, headers);
                setAdminData(res.data.data)
            } catch (error) {
                reject(error);
            }
        });
    };

 

    useEffect(() => {
        Dataoverview()
    }, []);


    // 
    return (
            <TableContainer mt={10} mb={10}>
                <Table variant="unstyled">
                    <Tbody>
                        <Tr>
                            <Th>Total Open Orders</Th>
                            <Td isNumeric>{adminData?.total_open_orders ?? 0}</Td>
                        </Tr>
                        <Tr>
                            <Th>Total Tron Required</Th>
                            <Td isNumeric>{toTron(adminData?.total_tron_required ?? 0)}</Td>
                        </Tr>
                        <Tr>
                            <Th>Available Platform Tron</Th>
                            <Td isNumeric>{toTron(adminData?.total_platform_tron) ?? 0}</Td>
                        </Tr>
                        <Tr>
                            <Th>Available/Staked Community Tron</Th>
                            <Td isNumeric>{ toTron(adminData?.available_community_tron ?? 0).toFixed(2)} Available / { toTron(adminData?.staked_community_tron ?? 0).toFixed(2) } Staked</Td>
                        </Tr>
                        <Tr>
                            <Th>Available Community Energy/Bandwidth</Th>
                            <Td isNumeric>{ (adminData?.totalDelegatableEnergy ?? 0 ).toFixed(2)} Energy / { (adminData?.totalDelegatableBandwidth ?? 0).toFixed(2) } Bandwidth</Td>
                        </Tr>
                        <Tr>
                            <Th>Total Community Tron</Th>
                            <Td isNumeric>{adminData?.total_community_tron ?? 0}</Td>
                        </Tr>
                        <Tr>
                            <Th>Community Members</Th>
                            <Td isNumeric>{adminData?.community_members ?? 0}</Td>
                        </Tr>
                        <Tr>
                            <Th>Pending Income Total/ No of Pending Income Users</Th>
                            <Td isNumeric>{toTron(adminData?.tronToSendAmount ?? 0)}/{adminData?.tronToSendUnsettledRequests??0}</Td>
                        </Tr>
                        <Tr>
                            <Th>Reclaimble Orders</Th>
                            <Td isNumeric>{adminData?.undelegatableCount ?? 0}</Td>
                        </Tr> 
                    </Tbody>
                </Table>
            </TableContainer>
    )
}

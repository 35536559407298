import { createSlice } from '@reduxjs/toolkit';
import { cancelOrder, getUserOrder } from '../common/api';
import isEmpty from 'is-empty';
import { ORDERSTATUS } from '../common/utils/constants';

const initialState = {
  isLoading: false,
  myOpenOrder: [],
  isCancelLoading: false,
  isCancelOpen: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsCancelLoading(state, action) {
      state.isCancelLoading = action.payload;
    },
    setMyOpenOrder(state, action) {
      state.myOpenOrder = action.payload;
    },
    setIsCancelOpen(state, action) {
      state.isCancelOpen = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setMyOpenOrder,
  setIsCancelLoading,
  setIsCancelOpen,
} = orderSlice.actions;

export default orderSlice.reducer;

export const getUserOrderHandler = (status) => async (dispatch, getState) => {
  try {
    const accountAddress = getState().account.accountAddress;
    if (isEmpty(accountAddress)) return;
    const res = await getUserOrder(accountAddress, status);
    dispatch(setMyOpenOrder(res));
  } catch (error) {}
};
export const cancelOrderHandler = (id) => async (dispatch, getState) => {
  try {
    dispatch(setIsCancelLoading(true));
    const res = await cancelOrder(id);

    return res;
  } catch (error) {}
};

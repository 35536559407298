import React from 'react'
import {
    Box,
    chakra,
    Container,
    List,
    ListItem,
} from "@chakra-ui/react";

export default function Stake() {
    return (
        <Box pt={5} px={{ base: 2, sm: 12, md: 17 }}>
            <chakra.h1
                textAlign={"center"}
                fontSize={"4xl"}
                fontWeight={"bold"}
                className="emboss"
                mb="5"
            >
                Stake and Earn
            </chakra.h1>

            <Container>
                <p>
                    Become the Tronweb Rentals provider and earn upto 18% APR on your Tron, Your tron never leave your wallet, staked from you wallet only.
                </p>
                <List spacing={3}>
                    <ListItem>
                        {/* <ListIcon as={MdCheckCircle} color='green.500' /> */}
                        1. Open wallet, click on profile icon the right bottom, and then click “Public Account Management”
                    </ListItem>
                    <ListItem>
                        {/* <ListIcon as={MdCheckCircle} color='green.500' /> */}
                        2. Make sure you have selected the right wallet. Under wallet management click on “Permission”.
                    </ListItem>
                    <ListItem>
                        {/* <ListIcon as={MdCheckCircle} color='green.500' /> */}
                        3. Now you are in Multi-signature, click “Add” on the right top
                    </ListItem>
                    {/* You can also use custom icons from react-icons */}
                    <ListItem>
                        {/* <ListIcon as={MdSettings} color='green.500' /> */}
                        4. Click “Add permissions” first, and select “Stake TRX, “Unstake TRX and “Vote” in the poped page. Then click “Confirm” on the bottom.
                    </ListItem>
                    <ListItem>
                        {/* <ListIcon as={MdSettings} color='green.500' /> */}
                        5. Back to the Add Permission Page。Input “Permission name”(eg. Stake and Earn) and Set other values like below:
                    </ListItem>
                    <ListItem>
                        6. Double check threshold, keys(address), weight, and click “Confirm”. Transaction Fee：100 TRX，Make sure your balance is greater than 100 TRX.
                    </ListItem>
                    <ListItem>
                        7. After authorization succeed! Click “Permission” again，you will see a new page listed. Now there are 3 dot(2 dots before authorization), move to the last dost, you will see the authorization detail.

                        <br />
                        (ignore the reward withdraw, its my own address, i do the vote reward withdrawal automatically)
                    </ListItem>
                </List>
            </Container>

        </Box>
    )
}

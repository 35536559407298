import React from 'react'
import {
    Box,
    chakra,
    List,
    ListItem,
} from "@chakra-ui/react";
export default function Terms() {
    return (
        <Box pt={5} px={{ base: 2, sm: 12, md: 17 }}>
            <chakra.h2
                fontSize={"2xl"}
                className="emboss"
                mb="3"
            >
                Terms and Conditions
            </chakra.h2>

            <p>
                “Tronweb.io” and its Affiliates (collectively, “we,” “our,” “us”, or “Tronweb.io” ) provide the following Terms of Use that, as they may be modified from time to time by “Tronweb.io” in its sole discretion (the “Terms”) apply to our users (“you” or “User(s)”) and govern each User’s access to, and use of, Tronweb.io’s products and services as well as our mobile and web-based application(s), our website(s), any software, programs, documentation, Internet-based services, and any updates (including software maintenance, service information, help content, bug fixes or maintenance releases) provided to you by “Tronweb.io”, directly or indirectly, through our mobile application, our website, or any other online services we provide (each a “Service,” and collectively the “Services”). By using tronweb.io or the tronweb.io service by any means ie API, mobile application, you (‘user’,’ your’,’ yourself’) are legally bound by the terms and services.</p>
            <br />
            <p>BEGINNING JULY 1, 2022 (THE “MODIFICATION DATE”), THE FOLLOWING TERMS SHALL APPLY:</p>
            <br />

            <chakra.h4
                fontSize={"2xl"}
                className="emboss"
                mt="3"
            >
                Terms of Use
            </chakra.h4>


            <List spacing={3}>
                <ListItem>
                    You have accepted these Terms & Conditions(including all terms) illustrated on the website.
                </ListItem>
                <ListItem>
                    You are at least 18 years of age
                </ListItem>
                <ListItem>
                    You are not using tronweb.io from the region where crypto is restricted or deemed illegal by the regional governing bodies.
                </ListItem>
                <ListItem>
                    You are not doing any Money Laundering activity on the site.
                </ListItem>
                <ListItem>
                    You are solely responsible for protecting your account password and other account information.
                </ListItem>


                <ListItem>
                    “Tronweb Rentals" hold rights to suspend/block/blacklist your account(with APIs) if found active in irregular activities.
                </ListItem>


                <ListItem>
                    The buy/sell of energy/bandwidth orders placed without using the tronweb.io website interface or active API Key will not be considered valid and “Tronweb Rentals” reserves the right to withhold or confiscate any cryptocurrency involved in un-authorized orders.
                </ListItem>

                <ListItem>
                    Do not use “Tronweb Rentals” if you do not agree to the Terms of Use described above. Your use of “Tronweb.io” means you agree to these Terms of Use.
                </ListItem>
            </List>

            <chakra.h4
                fontSize={"2xl"}
                className="emboss"
                mt="3"
            >
                Risks Involved
            </chakra.h4>

            <p> Before using any of Tronweb.io Services, you should ensure that you fully understand and can afford to undertake the risks involved. The risks listed below are intended to provide you with a general outline of the risks involved, but cannot capture all such risks.</p>
            <br />
            <p>Tronweb.io and our third-party partners may experience cyber-attacks, extreme market conditions, or other operational or technical difficulties which could result in the immediate halt of transactions either temporarily or permanently. Provided that Tronweb.io has taken reasonable commercial and operational measures to prevent such events in technical systems controlled by Tronweb.io, Tronweb.io is not and will not be responsible or liable for any loss or damage of any sort incurred by you as a result of such cyber-attacks, operational or technical difficulties or suspensions of transactions.</p>

            <chakra.h4
                fontSize={"2xl"}
                className="emboss"
                mt="3"
            >
                Disclosure of Tronweb.io Account Information
            </chakra.h4>

            <p>We may disclose information to third parties about you, your Tronweb.io Account, or the transactions you make:
                where it is necessary for the provision of our Services under these Terms; </p>
            <List spacing={3}>
                <ListItem>
                    in order to verify the existence and condition of your Tronweb.io Account for a third party, such as a referral partner; </ListItem>
                <ListItem>
                    in order to comply with any request or order by any government agency or competent court;</ListItem>
            </List>


            <chakra.h4
                fontSize={"2xl"}
                className="emboss"
                mt="3"
            >
                Force Majeure
            </chakra.h4>
            <p>
                We will not be liable for delays in processing or other non-performance caused by such events as fires, telecommunications, utility, or power failures, equipment failures, labor strife, riots, war, nonperformance of our vendors or suppliers, acts of God, pandemics or epidemic events, or other causes over which we have no reasonable control.
            </p>

        </Box>
    )
}

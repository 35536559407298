export const TO_ADDRESS = process.env.REACT_APP_TO_ADDRESS;
export const TRANSACTION_API = process.env.REACT_APP_TRANSACTION_API;
export const ACCOUNT_DETAILS = process.env.REACT_APP_ACCOUNT_DETAILS;
export const TRONSCAN_API_KEY = '3db59ffc-6c83-419a-aabd-7f7b7e3c991e';
export const TRANSACTION_RESOURCE_API = 'transaction-resource';
export const NONCE_API = `user/`;
export const USER_LOGIN_API = `login`;
export const ORDER_API = `order`;
export const FILL_OPEN_ORDER_API = `community-fill-order`;
export const TRADE_API = 'order/all/';
export const ENERGY = 'ENERGY';
export const BANDWIDTH = 'BANDWIDTH';
export const ORDERSTATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ONGOING: 'ONGOING',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
};
